<template>
  <div class="main">
    <!-- <div v-if="loading" class="spinner">
      <div class="spinner-border text-primary" role="status"></div>
    </div> -->
    <div class="algncnt table-header row" v-if="!loading">
      <!-- <div class="filter-content"> -->
      <div class="col-4 my-2">
        <!-- <div class="label">
          <span>Filter by Nft :</span>
        </div> -->
        <select
          class="form-select"
          label="Default select example"
          v-model="selectedNft"
          @change="getNftSales()"
        >
          <option value="null" disabled>Filter by Nft</option>
          <option
            v-for="nft in nfts"
            v-bind:value="nft"
            v-bind:key="nft.nft_name"
          >
            {{ nft.nft_name }}
          </option>
        </select>
      </div>
      <div class="col-4 my-2">
        <!-- <div class="label">
          <span>Filter by Token :</span>
        </div> -->
        <select
          class="form-select"
          label="Default select example"
          v-model="selectedToken"
          @change="getNftSales()"
        >
          <option value="null" disabled>Filter by Token</option>
          <option
            v-for="token in tokens"
            v-bind:value="token"
            v-bind:key="token.token_name"
          >
            {{ token.token_name }}
          </option>
        </select>
      </div>
      <div class="datepicker col-4 my-2">
        <!-- <div class="label">
          <span>Filter By Date Range :</span>
        </div> -->
        <DatePicker
          v-model="daterange"
          type="date"
          range
          confirm
          @confirm="getNftSales()"
          placeholder="Select date range"
          style="float: left"
        ></DatePicker>
      </div>
      <div class="col-4 my-2" v-if="filterdata">
        <button type="button" class="btn btn-secondary" @click="clearFilter()">
          Clear all
        </button>
      </div>
      <div class="col-4 my-2">
        <!-- <vue-json-to-csv :json-data="transaction" :csv-title="'NFT Sales'">
          <b-button> Download CSV </b-button>
        </vue-json-to-csv> -->
        <b-button @click="downloadCSV()"> Download CSV </b-button>
      </div>
      <!-- </div> -->
    </div>
    <br />

    <div class="loadoverlay" v-if="loading">
      <b-spinner></b-spinner>
    </div>
    <p v-if="!loading && isdata" class="nodata">
      No Data Available at the moment.
    </p>

    <div v-if="!loading && !isdata">
      <b-table
        hover
        :fields="fields"
        :items="transaction"
        :per-page="perPage"
        no-sort-reset
        outlined
      >
      </b-table>
      <b-pagination
        @change="getNftSales"
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="my-table"
        pills
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import VueJsonToCsv from "vue-json-to-csv";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { apiService } from "@/services/apiService";
import { utills } from "@/services/utill";

export default {
  data() {
    return {
      // sendUrl: "https://prediction-api.rage.fan",
      sendUrl: apiService.getUrl(),
      transaction: [],
      perPage: 15,
      currentPage: 1,
      loading: true,
      isdata: false,
      daterange: [],
      tokens: [],
      selectedToken: null,
      nfts: [],
      selectedNft: null,
      totalPages: 1,
      fields: [
        {
          key: "nft_name",
          label: "nft_name",
          color: "red",
        },
        {
          key: "nft_price",
          label: "nft_price",
        },
        {
          key: "created_time",
          label: "created_time",
        },
        {
          key: "buy_token_name",
          label: "buy Token ",
        },
        {
          key: "user_wallet_address",
          label: "user_wallet_address",
        },
        // {
        //   key: "btn",
        //   label: "Button",
        // },
      ],
    };
  },
  components: {
    VueJsonToCsv,
    DatePicker,
  },
  computed: {
    rows() {
      return this.transaction.length * this.totalPages;
    },
    filterdata() {
      if (
        this.daterange == null ||
        (this.daterange.length == 0 &&
          this.selectedToken == null &&
          this.selectedNft == null)
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    async getNftSales(e) {
      try {
        this.transaction = [];
        this.loading = true;
        if (e) {
          this.currentPage = e;
        }

        let from = null;
        let to = null;
        let nftId = null;
        let tokenId = null;
        if (this.daterange.length > 0) {
          from = this.daterange[0].getTime();
          to = this.daterange[1].getTime();
        }
        if (this.selectedToken) {
          tokenId = this.selectedToken.id;
        }
        if (this.selectedNft) {
          nftId = this.selectedNft.id;
        }
        const response = await apiService.getNftSales(
          this.currentPage,
          from,
          to,
          nftId,
          tokenId
        );
        // this.Tournaments= response.data.data.tournaments;
        // console.log("response", response.data);
        response.data.data.transactions.forEach((e) => {
          const data = {
            created_time: moment.utc(e.txn_time).format("DD-MM-YYYY-h:mm-A"),
            nft_name: e.nft_name,
            nft_price: e.nft_price,
            buy_token_name: e.buy_token_name,
            user_wallet_address: e.user_wallet_address,
          };
          this.transaction.push(data);
          // console.log(moment(e.end_date).format("DD-MM-YYYY h:mm"));
        });
        this.nfts = response.data.data.nfts;
        this.tokens = response.data.data.tokens;
        this.totalPages = response.data.data.totalPages;
        this.loading = false;
        //  Check for Length for pagination
        if (this.transaction.length < 12) {
          this.perPage = this.transaction.length;
        } else {
          this.perPage = 12;
        }
        // enable no data content
        if (this.transaction.length > 0) {
          this.isdata = false;
        } else {
          this.isdata = true;
        }
        // console.log(this.transaction);
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.isdata = true;
      }
    },
    // rowdata(data) {
    //   console.log(data.item);
    // },
    async clearFilter() {
      window.location.reload();
    },
    async downloadCSV() {
      let nftId = null;
      let tokenId = null;
      let from = null;
      let to = null;
      console.log("daterange", this.daterange);
      if (this.daterange.length > 0) {
        from = this.daterange[0].getTime();
        to = this.daterange[1].getTime();
      }
      if (this.selectedToken) {
        tokenId = this.selectedToken;
      }
      if (this.selectedNft) {
        // console.log("this.selectedSport", this.selectedSport);
        nftId = this.selectedNft;
      }
      const items = this.transaction.length * this.totalPages;
      console.log("items", items);
      try {
        const response = await apiService.getNftSales(
          this.currentPage,
          from,
          to,
          nftId,
          tokenId,
          items
        );
        console.log("data", response);
        utills.downloadCSV("NFT Sales.csv", response.data.data.transactions);
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    this.getNftSales();
  },
};
</script>

<style scoped>
/* .filter-content {
  display: flex;
  align-items: end;
}

.table-header {
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: space-between;
} */
</style>