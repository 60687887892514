<template>
  <div class="main">
    <div class="algncnt table-header row" v-if="!loading">
      <!-- <div class="filter-content"> -->
      <div class="col-4 my-2">
        <!-- <div class="label">
          <span>Filter by Tournament :</span>
        </div> -->
        <select
          class="form-select"
          label="Default select example"
          v-model="selectedTournament"
          @change="getCreatedNfts()"
        >
          <option value="null" disabled>Filter by Tournament</option>
          <option
            v-for="tour in tours"
            v-bind:value="tour"
            v-bind:key="tour.name"
          >
            {{ tour.name }}
          </option>
        </select>
      </div>
      <div class="datepicker col-4 my-2">
        <!-- <div class="label">
          <span>Filter by Date :</span>
        </div> -->
        <DatePicker
          v-model="daterange"
          type="date"
          range
          confirm
          @confirm="getCreatedNfts()"
          placeholder="Select date range"
          style="float: left"
        ></DatePicker>
      </div>
      <div class="col-4"></div>
      <div class="col-6 my-2">
        <button
          v-if="filterdata"
          type="button"
          class="btn btn-secondary"
          @click="clearFilter()"
        >
          Clear all
        </button>
      </div>
      <div class="col-6 my-2 d-flex justify-content-end">
        <!-- <vue-json-to-csv
          v-if="!loading"
          :json-data="createdNfts"
          :csv-title="'NFT Usage'"
        >
          <b-button> Download CSV </b-button>
        </vue-json-to-csv> -->
        <b-button @click="downloadCSV()"> Download CSV </b-button>
      </div>
      <!-- </div> -->
    </div>
    <br />
    <div class="loadoverlay" v-if="loading">
      <b-spinner v-if="loading"></b-spinner>
      <!-- <p v-if="createdNfts.length < 1" class="nodata">
        No Data Available at the moment.
      </p> -->
    </div>

    <div v-if="createdNfts.length > 0">
      <b-table
        table-class="text-nowrap"
        hover
        :fields="fields"
        :items="createdNfts"
        :per-page="perPage"
        outlined
        no-sort-reset
        responsive
      >
        <template #cell(btn)="data">
          <b-button
            size="sm"
            @click="$bvModal.show(data.item.id)"
            variant="primary"
          >
            View
          </b-button>
          <b-modal
            cancel-disabled="true"
            title="NFT Details"
            v-bind:id="data.item.id"
            size="lg"
            centered
            hide-footer
          >
            <!-- <template #modal-header-close>
              <button class="close-btn">x</button>
            </template> -->
            <div class="modal-align row">
              <div class="col-7 modal-nft-content px-3">
                <div class="d-flex align-items-center justify-content-start">
                  <span class="w-50 text-left"> NFT Name :</span>
                  <p>{{ data.item.nft_name }}</p>
                </div>
                <div
                  class="d-flex align-items-center justify-content-start my-2"
                >
                  <span class="w-50"> NFT Creation Date :</span>
                  <p>{{ data.item.created }}</p>
                </div>
                <div
                  class="d-flex align-items-center justify-content-start my-2"
                >
                  <span class="w-50">Tournament Name :</span>
                  <p>{{ data.item.tournament_name }}</p>
                </div>
                <div
                  class="d-flex align-items-center justify-content-start my-2"
                >
                  <span class="w-50"> Team Name : </span>
                  <p>{{ data.item.team_name }}</p>
                </div>
                <div
                  class="d-flex align-items-center justify-content-start my-2"
                >
                  <span class="w-50">NFT Price :</span>
                  <p>
                    {{ data.item.nft_price }} {{ data.item.reward_token_name }}
                  </p>
                </div>
                <div
                  class="d-flex align-items-center justify-content-start my-2"
                >
                  <span class="w-50"> Winning Amount :</span>
                  <p>
                    {{ data.item.reward_token }}
                    {{ data.item.reward_token_name }}
                  </p>
                </div>
                <div
                  class="d-flex align-items-center justify-content-start my-2"
                >
                  <span class="w-50"> Buy Token :</span>
                  <p>{{ data.item.token_name }}</p>
                </div>
                <div
                  class="d-flex align-items-center justify-content-start my-2"
                >
                  <span class="w-50"> Reward Token :</span>
                  <p>{{ data.item.reward_token_name }}</p>
                </div>

                <div
                  class="d-flex align-items-center justify-content-start my-2"
                >
                  <span class="w-50"> Total supply :</span>
                  <p>{{ data.item.total_supply }}</p>
                </div>
                <div
                  class="d-flex align-items-center justify-content-start my-2"
                >
                  <span class="w-50"> Available supply :</span>
                  <p>{{ data.item.available_supply }}</p>
                </div>
                <div
                  class="d-flex align-items-center justify-content-start my-2"
                >
                  <span class="w-50"> Sold :</span>
                  <p>{{ data.item.nfts_sold }}</p>
                </div>
                <div
                  class="d-flex align-items-center justify-content-start my-2"
                >
                  <span class="w-50"> Amount Raised :</span>
                  <p>
                    {{ data.item.amount_raised }}
                    {{ data.item.reward_token_name }}
                  </p>
                </div>
                <div
                  class="d-flex align-items-center justify-content-start my-2"
                >
                  <span class="w-50"> Is Nft Active :</span>
                  <p v-if="!is_nft_active">True</p>
                  <p v-else>False</p>
                </div>
              </div>
              <!-- <img src="https://picsum.photos/100" class="modal-img" /> <br /> -->
              <div class="col-5">
                <img :src="data.item.image_url" class="w-75" />
              </div>
            </div>
            <div class="w-100 d-flex justify-content-center align-items-center">
              <b-button
                variant="success"
                v-if="!data.item.is_nft_active"
                class="mt-3"
                @click="nftStatusChange(data.item.id, true)"
                >Enable</b-button
              >
              <b-button
                variant="danger"
                v-else
                class="mt-3"
                @click="nftStatusChange(data.item.id, false)"
                >Disable{{ is_nft_active }}</b-button
              >
            </div>
          </b-modal>
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        @change="getCreatedNfts"
        :total-rows="rows"
        :per-page="perPage"
        :page="page"
        aria-controls="my-table"
        pills
      ></b-pagination>
    </div>
    <div v-if="!loading && !createdNfts.length > 0" class="nodata">
      <p>No Data Available at the moment.</p>
    </div>
    <!-- <b-button v-b-modal.modal-center>Launch centered modal</b-button>

    <b-modal id="modal-center" centered title="BootstrapVue">
      <p class="my-4">Vertically centered modal!</p>
    </b-modal> -->
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import VueJsonToCsv from "vue-json-to-csv";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { apiService } from "@/services/apiService";
import { utills } from "@/services/utill";

export default {
  name: "creatednfts",
  data() {
    return {
      // sendUrl: "https://2f81-118-185-191-21.ngrok.io",
      sendUrl: apiService.getUrl(),
      moddata: [],
      daterange: [],
      createdNfts: [],
      perPage: 12,
      currentPage: 1,
      loading: true,
      isdata: false,
      page: 5,
      tours: [],
      totalPages: 1,
      selectedTournament: null,
      fields: [
        {
          key: "id",
          label: "id",
          color: "red",
        },
        {
          key: "nft_name",
          label: "nft_name",
        },
        {
          key: "created",
          label: "created",
        },
        {
          key: "tournament_name",
          label: "Tournament Name",
        },
        {
          key: "nft_price",
          label: "Nft Price",
        },
        // {
        //   key: "reward_token",
        //   label: "Winning Amount",
        // },
        // {
        //   key: "token_name",
        //   label: "Buy Token",
        // },
        // {
        //   key: "reward_token_name",
        //   label: "Reward Token",
        // },
        // {
        //   key: "team_name",
        //   label: "Team Name",
        // },
        // {
        //   key: "available_supply",
        //   label: "Available",
        // },
        // {
        //   key: "nfts_sold",
        //   label: "Sold",
        // },
        // {
        //   key: "amount_raised",
        //   label: "Amount Raised",
        // },
        {
          key: "is_nft_active",
          label: "is Active",
        },
        {
          key: "btn",
          label: "View",
        },
      ],
    };
  },
  components: {
    VueJsonToCsv,
    DatePicker,
  },
  computed: {
    rows() {
      return this.createdNfts.length * this.totalPages;
    },
    filterdata() {
      if (
        this.daterange == null ||
        (this.daterange.length == 0 && this.selectedTournament == null)
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    async nftStatusChange(id, status) {
      // console.log("status", id, status);
      this.loading = true;
      try {
        //
        const response = await apiService.changeNftStatus(id, status);
        console.log("response", response);
        this.$bvModal.hide(`${id}`);
        this.$swal("Success", `NFT Status Updated`, "success");
        this.getCreatedNfts();
      } catch (error) {
        console.log(error);
        this.$swal("Error", `Something Went Wrong`, "error");
      }
    },
    async getCreatedNfts(e) {
      try {
        this.createdNfts = [];
        this.loading = true;
        if (e) {
          this.currentPage = e;
        }

        let from = null;
        let to = null;
        let tourId = null;
        if (this.daterange.length > 0) {
          from = this.daterange[0].getTime();
          to = this.daterange[1].getTime();
        }
        // console.log("(this.selectedTournament", this.selectedTournament);
        if (this.selectedTournament) {
          // console.log("(this.selectedTournament", this.selectedTournament.id);
          tourId = this.selectedTournament.id;
        }
        // const response = await axios({
        //   url: `${this.sendUrl}/api/users/getNftByTournamentId`,
        //   params: { page: 1 },
        //   method: "get",
        //   headers: {
        //     Authorization: "Bearer " + this.$store.state.userCredentials.token,
        //   },
        // });
        // this.Tournaments= response.data.data.tournaments;
        const items = this.createdNfts.length * this.totalPages;
        const response = await apiService.getCreatedNfts(
          this.currentPage,
          from,
          to,
          tourId
        );
        // console.log("response", response.data);
        this.tours = response.data.data.tours;
        response.data.data.nfts.forEach((e) => {
          const data = {
            nft_used_time: moment
              .utc(e.nft_used_time)
              .format("YYYY-MM-DD, h:mm"),
            nft_name: e.nft_name,
            team_name: e.team_name,
            id: e.id,
            nft_price: e.nft_price,
            token_name: e.buy_token_name,
            tournament_name: e.tournament_name,
            total_supply: e.total_supply,
            nfts_sold: e.nfts_sold,
            available_supply:
              e.available_supply > 0 ? e.available_supply : "sold",
            reward_token: e.reward_token,
            reward_token_name: e.reward_token_name,
            amount_raised: e.amount_raised,
            is_nft_active: e.is_nft_active,
            image_url: e.image_url,
            created: moment.utc(e.created).format("DD-MM-YYYY-h:mm-A"),
          };
          this.createdNfts.push(data);
          // console.log(moment(e.end_date).format("YYYY-MM-DD"));
        });
        this.rows = response.data.data.totalPages;
        this.totalPages = response.data.data.totalPages;

        // console.log("totalpages",response.data.data.totalPages)
        //  Check for Length for pagination
        if (this.createdNfts.length < 12) {
          this.perPage = this.createdNfts.length;
        } else {
          this.perPage = 12;
        }
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.isdata = true;
      }
    },
    async downloadCSV() {
      let from = null;
      let to = null;
      let tourId = null;
      if (this.daterange.length > 0) {
        from = this.daterange[0].getTime();
        to = this.daterange[1].getTime();
      }
      if (this.selectedTournament) {
        tourId = this.selectedTournament.id;
      }

      const items = this.createdNfts.length * this.totalPages;

      try {
        const response = await apiService.getCreatedNfts(
          this.currentPage,
          from,
          to,
          tourId,
          items
        );
        let createdNftCsv = [];
        response.data.data.nfts.forEach((e) => {
          const data = {
            id: e.id,
            Nft_Name: e.nft_name,
            Tournament_Name: e.tournament_name,
            Team_Name: e.team_name,
            Nft_Price: e.nft_price,
            Token_Name: e.buy_token_name,
            Total_Supply: e.total_supply,
            Nfts_Sold: e.nfts_sold,
            Available_Supply:
              e.available_supply > 0 ? e.available_supply : "sold",
            Reward_Token_Name: e.reward_token_name,
            Reward_Token: e.reward_token,
            Amount_Raised: e.amount_raised,
            is_NFT_Active: e.is_nft_active,
            Created: moment.utc(e.created).format("DD-MM-YYYY-h:mm-A"),
          };
          createdNftCsv.push(data);
          // console.log(moment(e.end_date).format("YYYY-MM-DD"));
        });
        // console.log("response", response);
        utills.downloadCSV("NFTs.csv", createdNftCsv);
      } catch (error) {
        console.log(error);
      }
    },
    rowdata(data) {
      // console.log(data.item);
      this.moddata = data.item;
      // console.log("moddata", this.moddata);
    },
    async clearFilter() {
      window.location.reload();
    },
  },
  created() {
    this.getCreatedNfts();
  },
};
</script>
<style>
.close {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
  color: #000;
  border: none !important;
  /* padding: 10px; */
}
</style>
<style scoped>
/* .modal-img {
  height: 100px;
  width: 100px;
} */
/* .filter-content {
  display: flex;
  align-items: end;
} */
/* .loadoverlay {
  position: fixed;
  height: 80vh;
  width: 65vw;
  display: flex;
  align-items: center;
  justify-content: center;
} */
/* .table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
} */
.modal-align {
  text-align: center;
  border-bottom: 1px solid #eee;
}
.modal-nft-content span {
  text-align: left !important;
  font-weight: 600;
  font-size: 14px;
}
.modal-nft-content p {
  margin-bottom: 0;
}
/* .close {
  border: none !important;
}
.close-btn {
  color: #000 !important;
  padding: 10px !important;
  border-radius: 50%;
} */
</style>