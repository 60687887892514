import { ethers } from "ethers";
import buyAbi from "../assets/abi/buyContractAbi.json"
import { erc20 } from "./erc20";
import { utills } from "./utill";
// let factoryAddress = "0x3cE48A7467a0669bfd84C42BF8e7056f4fbd991b";

export const factory = {
    async create(admin, maxUsage, maxNFT, nftName, nftSymbol, url, buyToken, buyAmountWei, winningToken, winningAmount, drawAmount, factoryAddress, buyTokenDecimal) {
        try {
            console.log("init");
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();

            let allowance = await erc20.allowance(signer, factoryAddress, winningToken);

            // console.log("Got allowance", allowance, "winningAmount", winningAmount);

            // console.log("maxNFT", maxNFT, "buyTokenDecimal", buyTokenDecimal, "winningAmount", winningAmount);
            // let org = winningAmount * maxNFT;
            // console.log("logggggggg", utills.format(org, buyTokenDecimal));

            // console.log("compariosion", allowance, "WINDISDNIDN", utills.format(winningAmount, buyTokenDecimal), allowance >= utills.format(winningAmount, buyTokenDecimal) * maxNFT);


            if (allowance >= utills.format(winningAmount, buyTokenDecimal) * maxNFT) {
                // console.log("if part");
                let transaction = await this.executeTransaction(admin, maxUsage, maxNFT, nftName, nftSymbol, url, buyToken, buyAmountWei, winningToken, winningAmount, drawAmount, signer, factoryAddress);
                // console.log("before wait")
                let nftContract = await transaction.wait();
                // console.log(nftContract)
                return {
                    nftContractAddress: nftContract.events[1].args[0],
                    txHash: transaction.hash
                }

            } else {
                // console.log("elseeeeeeeeeeeeeeee");
                // let approve = await erc20.approve(signer, factoryAddress, winningToken);
                // console.log("Approve receipt", approve);
                let transaction = await this.executeTransaction(admin, maxUsage, maxNFT, nftName, nftSymbol, url, buyToken, buyAmountWei, winningToken, winningAmount, drawAmount, signer, factoryAddress);
                // console.log("else part", transaction);
                let nftContract = await transaction.wait();
                // console.log("transaction after approve", nftContract.events[1].args[0]);
                return {
                    nftContractAddress: nftContract.events[1].args[0],
                    txHash: transaction.hash
                }
            }
        }
        catch (e) {
            console.log("Error while create contract", e);
            throw (e);
        }
    },
    async executeTransaction(admin, maxUsage, maxNFT, nftName, nftSymbol, url, buyToken, buyAmountWei, winningToken, winningAmount, drawAmount, signer, factoryAddress) {
        // console.log(admin, maxUsage, maxNFT, nftName, nftSymbol, url, buyToken, buyAmountWei, winningToken, winningAmount, drawAmount, signer, factoryAddress);
        try {
            const buy = new ethers.Contract(factoryAddress, buyAbi, signer);
            let estimate;
            try {
                estimate = await buy.estimateGas.create(admin, maxUsage, maxNFT, nftName, nftSymbol, url, buyToken, buyAmountWei, winningToken, winningAmount, drawAmount);
            } catch (e) {
                // console.log("Error while estimete gas create NFT", e);
                // console.log(typeof e);
                if (e.reason == "execution reverted: Not owner") {
                    throw ('You are not an Owner')
                } else {

                    throw (`Error while estimete gas: ${e.reason}`);
                }
                // console.log(e.reason);

            }

            let res = await buy.create(admin, maxUsage, maxNFT, nftName, nftSymbol, url, buyToken, buyAmountWei, winningToken, winningAmount, drawAmount);
            console.log("created contract on sx", res);
            return res;
        } catch (e) {
            console.log("Error while execute transaction", e);
            throw (e)
        }
    }
}