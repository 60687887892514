<template>
  <div id="app">
    <div class="login-page">
      <transition name="fade">
        <div class="wallpaper-login"></div>
      </transition>
      <div class="wallpaper-register"></div>

      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-8 mx-auto">
            <div class="card login" v-bind:class="{ error: emptyFields }">
              <img src="../assets/rage-log0.png" alt="" class="rage-logo" />
              <h2 class="login-title">Sign In</h2>
              <form class="form-group">
                <input
                  v-bind:class="{ invalid: invalid }"
                  v-model="emailLogin"
                  type="email"
                  class="form-control my-2"
                  placeholder="Email"
                  required
                />
                <div class="aldiv">
                  <p v-if="errors.erremail" class="falert">
                    {{ errors.validemail }}
                  </p>
                </div>
                <div class="aldiv">
                  <p v-if="errors.erremail" class="falert">
                    {{ errors.erremail }}
                  </p>
                </div>
                <div class="input-group">
                  <input
                    v-bind:class="{ invalid: invalid }"
                    v-model="passwordLogin"
                    v-bind:type="[showPassword ? 'text' : 'password']"
                    class="form-control my-2"
                    placeholder="Password"
                    required
                  />
                  <div class="input-group-append my-2">
                    <span
                      class="input-group-text pwicon"
                      @click="showPassword = !showPassword"
                    >
                      <i
                        class="bx"
                        :class="[showPassword ? 'bx-hide' : 'bx-show']"
                        aria-hidden="true"
                      ></i>
                    </span>
                  </div>
                </div>
                <div class="aldiv">
                  <p v-if="errors.errpassword" class="falert">
                    {{ errors.errpassword }}
                  </p>
                </div>
                <div class="loginbtn">
                  <input
                    type="button"
                    value="Login"
                    class="btn btn-primary row my-3"
                    @click="doLogin"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import apiService from "../services/apiService";
import axios from "axios";
import { store } from "../store/index";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { apiService } from "@/services/apiService";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      emailLogin: { required, email },
    };
  },
  data() {
    return {
      errors: {},
      valid: null,
      showPassword: false,
      emailformat: false,
      registerActive: false,
      emailLogin: "",
      passwordLogin: "",
      emptyFields: false,
      invalid: false,
      alert: false,
      palert: false,
      validations: {
        emailLogin: {
          required,
        },
        passwordLogin: {
          required,
        },
      },
    };
  },
  methods: {
    async doLogin() {
      this.errors = {};
      this.valid = true;
      const validateEmail = (e) => {
        if (e == "") {
          return { valid: false, error: "Email is required" };
        }
        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e)) {
          return { valid: false, error: "Enter valid email." };
        }
        return { valid: true, error: null };
      };
      const validatePassword = (p) => {
        if (p == "") {
          return { valid: false, error: "Enter Password" };
        }
        return { valid: true, error: null };
      };
      this.errors = {};
      const validemail = validateEmail(this.emailLogin);
      this.errors.erremail = validemail.error;
      if (this.valid) {
        this.valid = validemail.valid;
      }
      const validpass = validatePassword(this.passwordLogin);
      this.errors.errpassword = validpass.error;
      if (this.valid) {
        this.valid = validpass.valid;
      }
      if (this.valid) {
        const data = {
          userEmail: this.emailLogin,
          password: this.passwordLogin,
        };
        try {
          const res = await apiService.loginData(data);
          console.log("response", res);
          if (res.status == 200) {
            this.$store.dispatch("setUserCredentials", {
              userEmail: res.data.data.email,
              token: res.data.data.token,
            });
            this.$router.push({ path: "/dashboard" });
          }
        } catch (e) {
          console.log(e);
          this.$swal("Invalid", `${e.response.data.message}`, "error");
          console.log(e.response.data);
        }
      }
      // if (
      //   this.emailLogin == "" ||
      //   this.passwordLogin == "" ||
      //   this.emailLogin == null ||
      //   this.passwordLogin == null
      // ) {
      //   this.emptyFields = true;
      //   if (this.emailLogin == "") {
      //     this.alert = true;
      //   } else {
      //     this.alert = false;
      //      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.emailLogin)){
      //     this.emailformat=true;
      //   }
      //   else{
      //     this.emailformat=false;
      //   }
      //   }
      //   if (this.passwordLogin == "") {
      //     this.palert = true;
      //   } else {
      //     this.palert = false;
      //   }

      // } else {
      //   const data = {
      //     userEmail: this.emailLogin,
      //     password: this.passwordLogin,
      //   };
      //   const res = await apiService.loginData(data);

      //   if (res.status == 200) {
      //     this.$store.dispatch("setUserCredentials", {
      //       userEmail: res.data.data.email,
      //       token: res.data.data.token,
      //     });
      //     this.$router.push({ path: "/dashboard" });
      //   } else {
      //     console.log("1");
      //   }
      //   // });
      // }
    },
    async onsubmit() {
      const result = await this.v$.$validate();
      // console.log("result", result);
      // console.log("validate", this.v$);
    },
  },
  created() {
    // console.log(this.$v);
  },
};
</script>


<style scoped>
p {
  line-height: 1rem;
}

.card {
  padding: 20px;
}

.login-page {
  align-items: center;
  display: flex;
  height: 100vh;
}

.wallpaper-login {
  background: url(https://images.pexels.com/photos/32237/pexels-photo.jpg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260)
    no-repeat center center;
  background-size: cover;
  height: 100%;
  position: absolute;
  width: 100%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.wallpaper-register {
  background: url(https://images.pexels.com/photos/533671/pexels-photo-533671.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260)
    no-repeat center center;
  background-size: cover;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: -1;
}

h1 {
  margin-bottom: 1.5rem;
}

.error {
  animation-name: errorShake;
  animation-duration: 0.3s;
}
.rage-logo {
  margin: auto;
  width: 125px;
  height: 125px;
}
.login-title {
  font-size: 38px;
  font-weight: 600;
}

@keyframes errorShake {
  0% {
    transform: translateX(-25px);
  }
  25% {
    transform: translateX(25px);
  }
  50% {
    transform: translateX(-25px);
  }
  75% {
    transform: translateX(25px);
  }
  100% {
    transform: translateX(0);
  }
}
.invalid {
  border: 2px solid red;
  background: rgba(255, 0, 0, 0.2);
}
.falert {
  color: red;
  margin: 0px;
  font-size: 8px !important;
}
.loginbtn {
  width: 100%;
}
.aldiv {
  width: 100%;
  text-align: left;
}
</style>