<template>
  <div>
    <SideBarVue
      :profileName="$store.state.userCredentials.userEmail"
    ></SideBarVue>
    <NavBar></NavBar>
    <router-view />
  </div>
</template>

<script>
import SideBarVue from "../../components/SideBar.vue";
import NavBar from "../../components/NavBar.vue";
export default {
  name: "Wrapper",
  components: {
    SideBarVue,
    NavBar,
  },
  methods: {
    search(val) {
      // console.log("Log", val);
    },
  },
};
</script>

<style>
</style>