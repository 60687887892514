<template>
  <div class="main">
    <!-- <div v-if="loading" class="spinner">
      <div class="spinner-border text-primary" role="status"></div>
    </div> -->
    <div class="algncnt page-header row" v-if="!loading">
      <div class="col-4 my-2">
        <span>Active Users : {{ activeUsers }}</span>
      </div>
      <!-- <div v-if="!loading && !isdata"></div> -->
      <div class="datepicker col-4" v-if="!loading">
        <!-- <div class="filter-content"> -->

        <!-- <div class="label">
          <span>Filter By Date Range :</span>
        </div> -->
        <DatePicker
          v-model="daterange"
          type="date"
          range
          confirm
          @confirm="getUsers()"
          format="DD/MM/YYYY"
          placeholder="Select date range"
          style="float: left"
        ></DatePicker>
        <!-- </div> -->
      </div>
      <div class="col-4"></div>
      <div class="col-6 my-2">
        <button
          v-if="filterdata"
          type="button"
          class="btn btn-secondary"
          @click="clearFilter()"
        >
          Clear all
        </button>
      </div>
      <div class="col-6 d-flex justify-content-end">
        <!-- <vue-json-to-csv
          class="csvbtn"
          v-if="!loading"
          :json-data="Users"
          :csv-title="'Users'"
        >
          <b-button> Download CSV </b-button>
        </vue-json-to-csv> -->
        <b-button @click="downloadCSV()"> Download CSV </b-button>
      </div>
    </div>
    <br />
    <div class="loadoverlay" v-if="loading || isdata">
      <b-spinner v-if="loading"></b-spinner>
      <p v-if="isdata" class="nodata">No Data Available at the moment.</p>
    </div>

    <div v-if="!loading && !isdata">
      <b-spinner v-if="loading"></b-spinner>

      <b-table
        hover
        :fields="fields"
        :items="Users"
        :per-page="perPage"
        no-sort-reset
        outlined
      >
        <!-- <template #cell(btn)="data">
          <b-button @click="rowdata(data)" size="sm"> click </b-button>
        </template> -->
      </b-table>
      <b-pagination
        v-model="currentPage"
        @change="getUsers"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="my-table"
        pills
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import VueJsonToCsv from "vue-json-to-csv";
import axios from "axios";
import moment from "moment";
import { apiService } from "@/services/apiService";
import { utills } from "@/services/utill";

export default {
  data() {
    return {
      // sendUrl: "https://prediction-api.rage.fan",
      sendUrl: apiService.getUrl(),
      Users: [],
      daterange: [],
      perPage: 12,
      currentPage: 1,
      loading: true,
      isdata: false,
      activeUsers: null,
      totalPages: 1,
      fields: [
        {
          key: "id",
          label: "User Id",
        },
        {
          key: "wallet_address",
          label: "wallet address",
          color: "red",
        },
        {
          key: "created",
          label: "created",
        },
        {
          key: "is_active",
          label: "is active",
        },
        {
          key: "no_of_bets",
          label: "No of Bets",
        },
        {
          key: "no_of_nfts",
          label: "No of Nfts",
        },
      ],
      totalUsers: null,
    };
  },
  components: {
    VueJsonToCsv,
    DatePicker,
  },
  computed: {
    rows() {
      return this.Users.length * this.totalPages;
    },
    filterdata() {
      if (this.daterange == null || this.daterange.length == 0) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    async getUsers(e) {
      this.Users = [];
      this.loading = true;
      try {
        if (e) {
          this.currentPage = e;
        }
        let from = null;
        let to = null;
        if (this.daterange.length > 0) {
          from = this.daterange[0].getTime();
          to = this.daterange[1].getTime();
          // this.Users = [];
          // this.loading = true;
        }
        // const response = await axios({
        //   url: `${this.sendUrl}/api/users/getUsers`,
        //   method: "get",
        //   headers: {
        //     Authorization: "Bearer " + this.$store.state.userCredentials.token,
        //   },
        // });
        // this.Tournaments= response.data.data.tournaments;
        // end_date
        const users = await apiService.getUsers(this.currentPage, from, to);
        console.log("users", users);
        users.data.data.users.forEach((e) => {
          const data = {
            created: moment(e.created).format("DD-MM-YYYY-h:mm-A"),
            wallet_address: e.wallet_address,
            is_active: e.is_active,
            id: e.id,
            no_of_bets: e.no_of_bets,
            no_of_nfts: e.no_of_nfts,
          };
          this.Users.push(data);
        });
        this.activeUsers = users.data.data.activeUsers;
        this.totalPages = users.data.data.totalPages;

        //  Check for Length for pagination
        if (this.Users.length < 12) {
          this.perPage = this.Users.length;
        } else {
          this.perPage = 12;
        }

        if (!this.Users.length > 0) {
          this.isdata = true;
        }
        this.loading = false;
      } catch (error) {
        console.log("errror", error);
        this.loading = false;
        this.isdata = true;
      }
    },
    async downloadCSV() {
      let from = null;
      let to = null;
      console.log("daterange", this.daterange);
      if (this.daterange.length > 0) {
        from = this.daterange[0].getTime();
        to = this.daterange[1].getTime();
      }

      const items = this.Users.length * this.totalPages;
      console.log("items", items);
      try {
        const response = await apiService.getUsers(
          this.currentPage,
          from,
          to,
          items
        );
        console.log("users", response);
        let users1 = [];
        response.data.data.users.forEach((e) => {
          const data = {
            created: moment(e.created).format("DD-MM-YYYY-h:mm-A"),
            wallet_address: e.wallet_address,
            is_active: e.is_active,
            id: e.id,
            no_of_bets: e.no_of_bets,
            no_of_nfts: e.no_of_nfts,
          };
          users1.push(data);
        });

        utills.downloadCSV("Users.csv", users1);
      } catch (error) {
        console.log(error);
      }
    },
    async clearFilter() {
      window.location.reload();
    },
    rowdata(data) {
      // console.log(data.item);
    },
  },
  created() {
    this.getUsers();
  },
};
</script>

<style >
.page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.totalUsers {
  padding: 10px;
}
</style>