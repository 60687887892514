<template>
  <div id="app">
    <!-- <SideBarVue @search-input-emit="search" /> -->
    <router-view />
  </div>
</template>
<script>
import firebase from "firebase";

export default {
  components: {},
  methods: {
    search(val) {
      // console.log("Log", val);
    },
  },
  created() {
    const firebaseConfig = {
      apiKey: "AIzaSyDJV1Z17loIWJyU5jCpcokr_t-xXQnFI_k",
      authDomain: "nft-prediction.firebaseapp.com",
      projectId: "nft-prediction",
      storageBucket: "nft-prediction.appspot.com",
      messagingSenderId: "132102316778",
      appId: "1:132102316778:web:15035a80df016ec35f6d93",
      measurementId: "G-QQFPS3ZYVZ",
    };

    // Initialize Firebase
    const app = firebase.initializeApp(firebaseConfig);
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
.spinner {
  position: fixed;
  height: 100vh;
  width: 100vw;
  opacity: 0.4;
  background: #fff;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pwicon{
  height:100%;
}
.loadoverlay {
  z-index: -3;
  position: fixed;
  top: 20%;
  height: 60vh;
  width: 65vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nodata {
  color: red;
  height: 70vh;
  /* text-align: center; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.filter-content {
  display: flex;
  align-items: end;
}

.table-header {
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.label {
  text-align: left;
  font-size: 15px;
}
.btn-align {
  text-align: left;
}
.table > thead {
  color: white;
  vertical-align: bottom;
  background: #11101d;
  border: 2px solid #11101d;
}
.table > tbody {
  border: 2px solid #11101d;
  vertical-align: inherit;
}
.page-link {
  color: #000 !important;
}
.page-item.active .page-link {
  background-color: #000 !important;
  border: none;
  color: #fff !important;
}
</style>
