<template>
  <div id="main">
    <div v-if="loading" class="spinner">
      <b-spinner></b-spinner>
      <!-- <div class="spinner-border text-primary" role="status"></div> -->
    </div>

    <div class="container">
      <form class="w-75 m-auto" @submit.prevent="onSubmit" method="post">
        <div class="header d-flex justify-content-between align-items-center">
          <!-- <h1 class="Title py-5">Create NFT</h1>
          <button
            v-if="!isWalletAddress"
            type="button"
            class="btn btn-primary btn-rounded"
            @click="connectMeta"
          >
            Connect MetaMask 
          </button>
          <p class="walletAdd" v-else>{{ $store.state.walletAddress }}</p> -->

          <!-- <button
            type="button"
            class="btn btn-primary btn-rounded"
            @click="goToNftDetails"
          >
            Nft Details
          </button> -->

          <!-- <button
            type="button"
            style="color: #fff; background-color: black"
            class="btn btn-primary btn-rounded"
            @click="logout"
          >
            Logout &nbsp; <img src="../assets/logout.png" />
          </button> -->
        </div>
        <div class="row mb-3">
          <!-- <label for="Count" class="col-sm-2 col-form-label">Select Play Type:</label> -->
          <div class="col-4 my-4">
            <div class="label">
              <span>Select a Sport :</span>
            </div>
            <select class="form-select" label="Default select example" @change="onSportsChange($event)"
              v-model="nft.selectedSport">
              <option disabled value="" selected>Select a Sport</option>
              <option v-for="Sport in Sports" v-bind:value="Sport.name" v-bind:key="Sport.name">
                {{ Sport.name }}
              </option>
            </select>
            <div class="error-msg" v-if="errors.selectedSport">
              {{ errors.selectedSport }}*
            </div>
          </div>
          <div class="col-4 my-4">
            <div class="label">
              <span>Select a Tournament :</span>
            </div>
            <select class="form-select" label="Default select example" @change="onTournamentChange($event)"
              v-model="nft.selectedTournament">
              <option disabled value="" selected>Select a Tournament</option>
              <option v-for="Tournament in Tournaments" v-bind:value="Tournament.name" v-bind:key="Tournament.name">
                {{ Tournament.name }}
              </option>
            </select>
            <div class="error-msg" v-if="errors.selectedTournament">
              {{ errors.selectedTournament }}*
            </div>
          </div>
          <div class="col-4 my-4">
            <div class="label">
              <span>Select a Team :</span>
            </div>
            <select class="form-select" label="Default select example" v-model="nft.selectedTeam"
              @change="checkNft($event)">
              <option value="" disabled>Select a Team</option>
              <option v-for="Team in Teams" v-bind:value="Team.name" v-bind:key="Team.name">
                {{ Team.name }}
              </option>
            </select>
            <div class="error-msg" v-if="errors.selectedTeam">
              {{ errors.selectedTeam }}*
            </div>
          </div>
          <!-- </div> -->
          <!-- <div class="row mb-3"> -->
          <!-- <label for="payout" class="col-sm-2 col-form-label">Enter Payout amount:</label> -->
          <div class="col-sm-6 px-3 my-3">
            <div class="label">
              <span>NFT Name :</span>
            </div>
            <input type="text" class="form-control" v-model="nft.nftName" placeholder="Enter the NFT Name" />
            <div class="error-msg" v-if="errors.nftName">
              {{ errors.nftName }}*
            </div>
          </div>
          <div class="col-sm-6 px-3 my-3">
            <div class="label">
              <span>NFT Symbol :</span>
            </div>
            <input type="text" class="form-control" v-model="nft.nftSymbol" placeholder="Enter the NFT Symbol" />
            <div class="error-msg" v-if="errors.nftSymbol">
              {{ errors.nftSymbol }}*
            </div>
          </div>
          <div class="col-sm-6 px-3 my-3">
            <div class="label">
              <span>No of NFTs :</span>
            </div>
            <input type="number" class="form-control" v-model.number="nft.nftForTeam" v-on:input="calcWin" id="Count"
              placeholder="Enter the No of NFTs" />
            <div class="error-msg" v-if="errors.nftForTeam">
              {{ errors.nftForTeam }}*
            </div>
          </div>
          <div class="col-sm-6 px-3 my-3">
            <div class="label">
              <span>No of Times NFT can be Used :</span>
            </div>
            <input type="number" class="form-control" v-model.number="nft.timeNftUsed" v-on:input="calcWin" id="price"
              placeholder="Enter the No of Times NFT can be Used" />
            <div class="error-msg" v-if="errors.timeNftUsed">
              {{ errors.timeNftUsed }}*
            </div>
          </div>
          <div class="col-sm-6 px-3 my-2">
            <div class="label">
              <span>Payout amount for each win :</span>
            </div>
            <input type="number" class="form-control" v-model.number="nft.payout" v-on:input="calcWin" step="0.01"
              name="payout" placeholder="Enter the Payout amount for each win" />
            <div class="win-calc">
              <small class="text-success" v-if="winAmount">Total Winning amount : {{ winAmount }}</small>
            </div>
            <div class="error-msg" v-if="errors.payout">
              {{ errors.payout }}*
            </div>
          </div>

          <div class="col-sm-6 px-3 my-3">
            <div class="label">
              <span>Select Network :</span>
            </div>
            <div class="form-group">
              <select v-model="nft.selectedNetWork" class="form-select" label="Default select example"
                @change="getTokensByNetwork($event)">
                <option value="" disabled>Select Network</option>
                <option v-for="(network, idx) in networks" v-bind:value="network.name" :key="idx">
                  {{ network.name }}
                </option>
              </select>
              <div class="error-msg" v-if="errors.selectedNetWork">
                {{ errors.selectedNetWork }}*
              </div>
            </div>
          </div>
          <div class="col-sm-6 px-3 my-3">
            <div class="label">
              <span>Select Reward Token :</span>
            </div>
            <div class="form-group">
              <select v-model="nft.selectedToken" class="form-select" label="Default select example">
                <option value="" disabled>Select Reward Token</option>
                <option v-for="(token, idx) in tokens" v-bind:value="token.token_name" v-bind:key="idx">
                  {{ token.token_name }}
                </option>
              </select>
              <div class="error-msg" v-if="errors.selectedToken">
                {{ errors.selectedToken }}*
              </div>
            </div>
          </div>

          <!-- <div class="col-sm-6 px-3 my-3">
            <div class="form-group">
              <select
                v-model="nft.seletedBuyNetWork"
                class="form-control"
                label="Default select example"
                @change="getTokensByNetwork($event)"
              >
                <option value="" disabled>Select Buy Network</option>
                <option
                  v-for="network in networks"
                  v-bind:value="network.name"
                  v-bind:key="network.name"
                >
                  {{ network.name }}
                </option>
              </select>
              <div class="error-msg" v-if="errors.selectedNetWork">
                {{ errors.selectedNetWork }}*
              </div>
            </div>
          </div> -->
          <div class="col-sm-6 px-3 my-3">
            <div class="label">
              <span>Select Buy Token :</span>
            </div>
            <div class="form-group">
              <select v-model="nft.selectedBuyToken" class="form-select" label="Default select example">
                <option value="" disabled>Select Buy Token</option>
                <option v-for="(token, idx) in tokens" v-bind:value="token.token_name" v-bind:key="idx">
                  {{ token.token_name }}
                </option>
              </select>
              <div class="error-msg" v-if="errors.selectedBuyToken">
                {{ errors.selectedBuyToken }}*
              </div>
            </div>
          </div>
          <div class="col-sm-6 px-3 my-2">
            <div class="label">
              <span>Draw Amount :</span>
            </div>
            <input type="number" class="form-control" v-model.number="nft.drawAmount" step="0.01" id="Count"
              placeholder="Enter the Draw Amount" />
            <div class="error-msg" v-if="errors.drawAmount">
              {{ errors.drawAmount }}*
            </div>
          </div>
          <div class="col-sm-6 px-3 my-2">
            <div class="label">
              <span>NFT price :</span>
            </div>
            <input type="number" class="form-control" v-model.number="nft.nftPrice" step="0.01" id="price"
              placeholder="Enter the NFT price" />
            <div class="error-msg" v-if="errors.nftPrice">
              {{ errors.nftPrice }}*
            </div>
          </div>

          <div class="col-sm-6 px-3 my-2">
            <div class="label">
              <span>NFT Description :</span>
            </div>
            <textarea name="desc" class="form-control" rows="5" placeholder="Enter the NFT Description"
              v-model="nft.desc"></textarea>
            <div class="error-msg" v-if="errors.desc">{{ errors.desc }}*</div>
          </div>
          <div class="col-sm-6 px-3 my-2" v-if="winAmount">
            <div class="label winlabel">
              <span>Winning Amount :</span>
            </div>
            <input v-if="winAmount" disabled name="desc" class="form-control textArea winam" rows="4" placeholder=""
              v-model="winAmount" />
          </div>
          <div class="col-sm-7 align-left px-3 my-3">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"
                v-model="selectNftType" v-bind:value="'image'" @change="typeChange" checked />
              <label class="form-check-label" for="flexRadioDefault1">
                Image/GIF
              </label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"
                v-model="selectNftType" v-bind:value="'video'" @change="typeChange" />
              <label class="form-check-label" for="flexRadioDefault2">
                Video
              </label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"
                v-model="selectNftType" v-bind:value="'audio'" @change="typeChange" />
              <label class="form-check-label" for="flexRadioDefault2">
                Audio
              </label>
            </div>
          </div>
        </div>

        <div class="row mb-3">
          <label for="price" v-if="selectNftType === 'image'" class="col-sm-2 col-form-label my-3">Upload NFT
            image:</label>
          <div class="col-sm-4 my-3" v-if="selectNftType === 'image'">
            <input type="file" accept="image/*" @change="getImage" />
            <small class="text-success">Image size should be Lesser than 5 Mb</small>
            <div class="error-msg" v-if="errors.assetUrl">
              {{ errors.assetUrl }}*
            </div>
            <div id="preview" class="preview" v-if="nft.assetUrl">
              <img v-if="firebaseImageUrl" :src="firebaseImageUrl" />
              <div class="upload" v-bind:class="{ isUpload: isUplaod === true }">
                <span>Uploading({{ uploadValue }}%)....</span>
              </div>
              <!-- <div v-if="item.imageUrl" class="progress-container">
                <span> Uploading({{ uploadValue }})....</span>
              </div> -->
            </div>
          </div>

          <div v-if="selectNftType === 'image' && isGiphy" class="col-6"></div>

          <label for="price" v-if="selectNftType === 'image' && isGiphy" class="col-sm-2 col-form-label my-3">Upload GIF
            Thumbnail Image:</label>
          <div class="col-sm-4 my-3" v-if="selectNftType === 'image' && isGiphy">
            <input type="file" accept="image/gif" @change="getGif" />
            <small class="text-success">GIF size should be Lesser than 500 kb</small>
            <div class="error-msg" v-if="errors.lazyImageUrl">
              {{ errors.lazyImageUrl }}*
            </div>
            <div id="preview" class="preview" v-if="lazyImageUrl">
              <img v-if="lazyImageUrl" :src="lazyImageUrl" />
              <!-- <div
                class="upload"
                v-bind:class="{ isUpload: isUplaod === true }"
              >
                <span>Uploading({{ uploadValue }}%)....</span>
              </div> -->
              <!-- <div v-if="item.imageUrl" class="progress-container">
                <span> Uploading({{ uploadValue }})....</span>
              </div> -->
            </div>
          </div>

          <label for="video" class="col-sm-2 col-form-label my-3" v-if="selectNftType === 'video'">Upload NFT
            Video:</label>
          <div class="col-sm-4 my-3" v-if="selectNftType === 'video'">
            <input type="file" accept="video/mp4,video/x-m4v,video/*" @change="getImage" />
            <small class="text-success">Video size should be Lesser than 5 Mb</small>

            <div class="error-msg" v-if="errors.assetUrl">
              {{ errors.assetUrl }}*
            </div>
            <div v-if="nft.assetUrl" class="preview">
              <!-- <img v-if="item.imageUrl" :src="item.imageUrl" /> -->
              <video autoplay :src="firebaseImageUrl"></video>
              <div class="upload" v-bind:class="{ isUpload: isUplaod === true }">
                <span>Uploading({{ uploadValue }}%)....</span>
              </div>
            </div>
          </div>
          <div v-if="selectNftType === 'video'" class="col-6"></div>
          <label for="Thumbnail" class="col-sm-2 col-form-label my-3" v-if="selectNftType === 'video'">Thumbnail
            Image:</label>
          <div class="col-sm-4 my-3" v-if="selectNftType === 'video'">
            <input type="file" accept="image/*" @change="getTumb" />
            <small class="text-success">Image size should be Lesser than 5 Mb</small>
            <div class="error-msg" v-if="errors.thumbImage">
              {{ errors.thumbImage }}*
            </div>
            <div v-if="thumbImage" id="preview" class="preview">
              <img :src="firebaseThumbImageUrl" />
              <div class="upload" v-bind:class="{ isUpload: isThumbUpload === true }">
                <span>Uploading({{ thumbUpload }}%)....</span>
              </div>
            </div>
          </div>
          <label for="price" class="col-sm-2 col-form-label my-3" v-if="selectNftType === 'audio'">Upload NFT
            Audio:</label>
          <div class="col-sm-4 my-3" v-if="selectNftType === 'audio'">
            <input type="file" accept="audio/*" @change="getImage" />
            <small class="text-success">File size should be Lesser than 5 Mb</small>
            <div class="error-msg" v-if="errors.assetUrl">
              {{ errors.assetUrl }}*
            </div>
            <div id="" v-if="nft.assetUrl" class="preview">
              <!-- <img v-if="item.imageUrl" :src="item.imageUrl" /> -->
              <audio controls="controls" class="" :src="firebaseImageUrl"></audio>

              <div class="upload" v-bind:class="{ isUpload: isUplaod === true }">
                <span>Uploading({{ uploadValue }}%)....</span>
              </div>
            </div>
          </div>
          <div v-if="selectNftType === 'audio'" class="col-6"></div>

          <label for="Thumbnail" class="col-sm-2 col-form-label my-3" v-if="selectNftType === 'audio'">Thumbnail
            Image:</label>
          <div class="col-sm-4 my-3" v-if="selectNftType === 'audio'">
            <input type="file" accept="image/*" @change="getTumb" />
            <small class="text-success">Image size should be Lesser than 5 Mb</small>
            <div class="error-msg" v-if="errors.thumbImage">
              {{ errors.thumbImage }}*
            </div>
            <div v-if="thumbImage" id="preview" class="preview">
              <img :src="firebaseThumbImageUrl" />
              <div class="upload" v-bind:class="{ isUpload: isThumbUpload === true }">
                <span>Uploading({{ thumbUpload }}%)....</span>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="row mb-3"> -->
        <!-- <label for="Count" class="col-sm-2 col-form-label">No of NFTs for the Team:</label> -->

        <!-- </div> -->
        <div class="pb-5">
          <button type="submit" :disabled="isEnable === false" class="button-71"
            v-bind:class="{ disableBtn: isEnable === false }">
            Submit
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { store } from "../store/index";
import { ethers } from "ethers";
import axios from "axios";
// import { store } from "../store/index";
import buffer from "buffer";
import { factory } from "../services/blockchainService";
import { utills } from "../services/utill";
import firebase from "firebase";
import { apiService } from "@/services/apiService";

export default {
  data() {
    return {
      // sendUrl: "https://prediction-api.rage.fan",
      sendUrl: apiService.getUrl(),
      store,
      user: {
        email: "",
        password: "",
        name: "",
        phone: "",
      },
      valid: true,
      success: false,
      errors: {},
      message: null,
      validation: {
        payout: 0,
        nftPrice: 1,
        timeNftUsed: 200,
        nftForTeam: 1,
        maxNft: 1000,
      },
      nft: {
        payout: "",
        nftPrice: "",
        timeNftUsed: "",
        nftForTeam: "",
        selectedTeam: "",
        selectedToken: "",
        selectedTournament: "",
        selectedSport: "",
        nftName: "",
        nftSymbol: "",
        selectedNetWork: "",
        // seletedBuyNetWork: "",

        selectedBuyToken: "",
        drawAmount: "",
        desc: "",
        assetUrl: null,
      },
      Teams: [],
      Tournaments: [],
      tokens: [],
      Sports: [],
      nftJsonUrl: null,
      networks: [],
      selectNftType: "image",
      item: {
        //...
        image: null,
        imageUrl: null,
        audioUrl: null,
        videoUrl: null,
      },
      loading: true,
      selectedItem: {},
      isWalletAddress: false,
      firebaseImageUrl: null,
      uploadValue: 0,
      isEnable: true,
      thumbImage: null,
      isThumb: false,
      isUplaod: false,
      firebaseThumbImageUrl: null,
      isThumbUpload: false,
      thumbUpload: 0,
      winAmount: null,
      lazyImageUrl: null,
      isGiphy: false,
    };
  },
  methods: {
    goToNftDetails() {
      this.$router.push({ path: "/nftDetails" });
    },
    logout() {
      this.$store.dispatch("logout");
    },
    async connectMeta() {
      if (typeof window.ethereum !== "undefined") {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const acc = await provider.send("eth_requestAccounts", []);
        if (acc.length > 0) {
          this.isWalletAddress = true;
          this.$store.dispatch("setWalletAddress", acc[0]);
        } else {
          this.$swal("Missing", `MetaMask Not Connected`, "error");
        }
      } else {
        this.$swal("Missing", `MetaMask not installed`, "error");
      }
    },

    onUpload(file) {
      this.firebaseImageUrl = null;

      const storageRef = firebase
        .storage()
        .ref(
          `nftCollection/${new Date().toDateString()}/${new Date().toISOString()}`
        )
        .put(file);
      storageRef.on(
        "state_changed",
        (snapshot) => {
          this.uploadValue = parseInt(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
        },
        (error) => {
          this.$swal;
          this.$swal("UpLoad Failed", "Try Again Later", "error");
        },
        () => {
          this.uploadValue = 100;

          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            this.firebaseImageUrl = url;
          });
          if (!this.isGiphy) {
            this.compresssImage(file);
          }
          this.isUplaod = false;
        }
      );
    },
    typeChange() {
      this.nft.assetUrl = null;
      this.errors = {};
      this.isGiphy ? false : true;
    },
    aboutPage() {
      this.$router.push({ path: "/about" });
    },
    makeActive(route) {
      this.$router.push({ path: `/${route}` });
    },
    getGif(e) {
      const file = e.target.files[0];
      const self = this;
      if (file.size < 512000) {
        const storageRef1 = firebase
          .storage()
          .ref(
            `LazyGifImages/${new Date().toDateString()}/${new Date().toISOString()}`
          )
          .put(file);
        storageRef1.on(
          "state_changed",
          (snapshot) => { },
          (error) => {
            this.$swal;
            this.$swal("UpLoad Failed", "Try Again Later", "error");
          },
          () => {
            // this.uploadValue = 100;

            storageRef1.snapshot.ref.getDownloadURL().then((url) => {
              self.lazyImageUrl = url;
              self.isEnable = true;
              console.log("firebase lazyImageUrl", self.lazyImageUrl);
            });
          }
        );
      } else {
        this.$swal("Required", `File Size should be less than 500 kb`, "error");
      }
    },

    getTumb(e) {
      const file = e.target.files[0];
      // console.log("file", file);
      if (file.size < 5242880) {
        this.isEnable = false;
        let self = this;
        self.isThumbUpload = true;
        self.uploadValue = 0;
        const ipfsAPI = require("ipfs-http-client");
        const ipfs = ipfsAPI.create({
          host: "ipfs.infura.io",
          port: 5001,
          protocol: "https",
        });
        let reader = new FileReader();
        reader.onloadend = async function () {
          const buf = buffer.Buffer(reader.result);
          try {
            let dataIPF = await ipfs.add(buf);
            self.thumbImage = "https://gateway.ipfs.io/ipfs/" + dataIPF.path;
            self.uploadThumbImage(file);
          } catch (error) {
            // console.log("err", error);
          }
        };
        // var ipfsAPI = require("ipfs-api");
        // var ipfs = ipfsAPI("ipfs.infura.io", "5001", { protocol: "https" });
        // let reader = new FileReader();
        // reader.onloadend = function () {
        //   const buf = buffer.Buffer(reader.result);

        //   ipfs.add(buf, (err, result) => {
        //     self.thumbImage = "https://gateway.ipfs.io/ipfs/" + result[0].hash;
        //     console.log("thumbImage", self.thumbImage);
        //   });
        //   self.uploadThumbImage(file);
        // };
        if (e.target.files[0]) {
          reader.readAsArrayBuffer(e.target.files[0]);
        }
      } else {
        this.$swal("Required", `File Size should be less than 5 mb`, "error");
      }
    },
    uploadThumbImage(file) {
      this.firebaseThumbImageUrl = null;
      const storageRef = firebase
        .storage()
        .ref(
          `Nft_Thumbnail_Collection/${new Date().toDateString()}/${new Date().toISOString()}`
        )
        .put(file);
      storageRef.on(
        "state_changed",
        (snapshot) => {
          this.thumbUpload = parseInt(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
        },
        (error) => {
          this.$swal;
          this.$swal("UpLoad Failed", "Try Again Later", "error");
        },
        () => {
          this.thumbUpload = 100;

          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            this.firebaseThumbImageUrl = url;
            this.compresssImage(file);
            // this.isEnable = true;
          });
          this.isThumbUpload = false;
        }
      );
    },
    async checkNft($event) {
      this.loading = true;
      try {
        let getId;
        let teamId;
        this.Tournaments.map((e) => {
          if (e.name === this.nft.selectedTournament) {
            getId = e.id;
          }
        });
        this.Teams.map((e) => {
          if (e.name === $event.target.value) {
            teamId = e.id;
          }
        });
        const response = await axios({
          url: `${this.sendUrl}/api/auth/nftExists`,
          method: "get",
          params: {
            tourId: getId,
            teamId: teamId,
          },
          headers: {
            Authorization: "Bearer " + this.$store.state.userCredentials.token,
          },
        });

        if (response.data.data) {
          this.loading = false;
          this.$swal(
            "Not Allowed",
            `NFT Already Created for this Team`,
            "error"
          );
          this.nft.selectedTeam = "";
        } else {
          this.loading = false;
        }
      } catch (error) {
        this.loading = false;
        console.log(error.message);
      }
    },
    async compresssImage(file) {
      // console.log("real image", file);
      const self = this;

      const blobURL = window.URL.createObjectURL(file);
      const img = new Image();
      img.src = blobURL;
      img.onload = function (ev) {
        window.URL.revokeObjectURL(blobURL); // release memory
        // Use the img
        const canvas = document.createElement("canvas");
        canvas.width = 300;
        canvas.height = 400;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, 300, 400);
        canvas.toBlob(
          function (blob) {
            // Handle the compressed image
            // console.log("image", blob);

            blob.name = "test.jpeg";
            blob.lastModified = new Date().toLocaleString();
            const myFile = new File([blob], "test.jpeg", {
              type: blob.type,
            });
            const storageRef1 = firebase
              .storage()
              .ref(
                `LazyImages/${new Date().toDateString()}/${new Date().toISOString()}`
              )
              .put(myFile);
            storageRef1.on(
              "state_changed",
              (snapshot) => {
                // this.uploadValue =
                //   this.uploadValue +
                //   parseInt(
                //     ((snapshot.bytesTransferred / snapshot.totalBytes) * 100) /
                //       2
                //   );
                // console.log("this.uploadValue", this.uploadValue);
              },
              (error) => {
                this.$swal;
                this.$swal("UpLoad Failed", "Try Again Later", "error");
              },
              () => {
                // this.uploadValue = 100;

                storageRef1.snapshot.ref.getDownloadURL().then((url) => {
                  self.lazyImageUrl = url;
                  self.isEnable = true;
                  // console.log("firebase lazyImageUrl", self.lazyImageUrl);
                });
              }
            );
          },
          "image/png",
          0.6
        );
      };
    },
    async getImage(e) {
      const file = e.target.files[0];

      if (file.size < 5242880) {
        this.isEnable = false;
        let self = this;
        self.isUplaod = true;
        self.uploadValue = 0;
        self.thumbImage = "";
        self.assetUrl = null;
        let projectId = "2Dtrp7tYuoGdn0ARHBDxOyXPVoz";
        let SecretKey = "97fdcc59de661db2c94bb861420d115a";
        const auth =
          "Basic " + buffer.Buffer.from(projectId + ":" + SecretKey).toString("base64");
        const ipfsAPI = require("ipfs-http-client");
        const ipfs = ipfsAPI.create({
          host: "ipfs.infura.io",
          port: 5001,
          protocol: "https",
          headers: {
            authorization: auth,
          }
        });
        // let ipfs = ipfsAPI("ipfs.infura.io", "5001", { protocol: "https" });
        let reader = new FileReader();
        reader.onloadend = async function () {
          const buf = buffer.Buffer(reader.result);
          try {
            let dataIPF = await ipfs.add(buf);
            self.nft.assetUrl = "https://gateway.ipfs.io/ipfs/" + dataIPF.path;
            console.log("dataIPF", self.nft.assetUrl);
            if (file.type === "image/png" || file.type === "image/jpeg") {
              self.isThumb = false;
            }
            if (file.type === "video/mp4" || file.type === "audio/mpeg") {
              self.isThumb = true;
            }
            if (file.type === "image/gif") {
              // console.log("gihdhfhd");
              self.isGiphy = true;
            }
            self.onUpload(file);
          } catch (error) {
            console.log("err", error);
          }
        };
        if (e.target.files[0]) {
          reader.readAsArrayBuffer(e.target.files[0]);
        }
      } else {
        this.$swal("Required", `File Size should be less than 5 mb`, "error");
      }
      // self.image = file;

      // console.log("iamge", file);
      // console.log("iamge", file.size < 2097152);
      // if (file.size < 2097152) {
      //   this.image = file;
      //   this.item.imageUrl = URL.createObjectURL(file);
      // } else {
      //   this.$swal(
      //     "Required",
      //     `Image Size should be less than 20480 kb`,
      //     "error"
      //   );
      // }
    },
    async checkConnectedWalletExist() {
      // console.log("enteddrde");
      try {
        const { ethereum } = window;
        const accounts = await ethereum.request({ method: "eth_accounts" });
        if (accounts.length !== 0) {
          const account = accounts[0];
          this.isWalletAddress = true;
          this.$store.dispatch("setWalletAddress", account);
          // console.log("logggg", this.$store.state.walletAddress);
        } else {
          this.isWalletAddress = false;
          // console.log("logggg", this.$store.state.walletAddress);

          return false;
        }
      } catch (error) {
        this.isWalletAddress = false;
        console.log("error", error);
      }
    },
    resetAll() {
      this.nft.payout = "";
      this.nft.nftPrice = "";
      this.nft.timeNftUsed = "";
      this.nft.nftForTeam = "";
      this.nft.selectedTeam = "";
      this.nft.selectedToken = "";
      this.nft.selectedTournament = "";
      this.nft.selectedSport = "";
      this.nft.nftName = "";
      this.nft.nftSymbol = "";
      this.nft.selectedNetWork = "";
      this.nft.selectedBuyToken = "";
      this.nft.drawAmount = 0;
      this.nft.desc = "";
      this.nft.assetUrl = null;
      this.errors = {};
      this.firebaseImageUrl = "";
      this.isEnable = false;
      this.winAmount = null;
      if (this.isThumb) {
        this.thumbImage = null;
      }
      this.Teams = [];
      this.Tournaments = [];
      this.tokens = [];
    },
    async onSubmit() {
      this.errors = {};
      this.valid = true;
      // console.log("valuesss", this.nft.payout, this.nft.drawAmount);
      const validatePayout = (pay) => {
        if (pay === "") {
          return { valid: false, error: "This field is required" };
        }
        if (pay <= this.validation.payout) {
          return {
            valid: false,
            error: `Payout amount should be greater than ${this.validation.payout}`,
          };
        }
        return { valid: true, error: null };
      };
      const validateNftPrice = (e) => {
        if (e === "") {
          return { valid: false, error: "This field is required" };
        }
        if (e < this.validation.nftPrice) {
          return {
            valid: false,
            error: `NFT price should be greater than or Equal to ${this.validation.nftPrice}`,
          };
        }
        return { valid: true, error: null };
      };
      const validateTimesNFT = (e) => {
        if (e === "") {
          return { valid: false, error: "This field is required" };
        }
        if (e > this.validation.timeNftUsed) {
          return {
            valid: false,
            error: `No of times NFT should be Lesser than or Equal to ${this.validation.timeNftUsed}`,
          };
        }
        if (e <= 0) {
          return {
            valid: false,
            error: `No of times NFT should be Greater than 0`,
          };
        }
        return { valid: true, error: null };
      };

      const validateNftForTeam = (e) => {
        if (e === "") {
          return { valid: false, error: "This field is required" };
        }
        if (e < this.validation.nftForTeam) {
          return {
            valid: false,
            error: `No of NFT for the team  should be greater than or Equal to ${this.validation.nftForTeam}`,
          };
        }
        if (e > this.validation.maxNft) {
          return {
            valid: false,
            error: `No of NFT for the team  should be Less than or Equal to ${this.validation.maxNft}`,
          };
        }
        return { valid: true, error: null };
      };
      const validateSelectSport = (e) => {
        if (e === "") {
          return { valid: false, error: "Please Select One" };
        }
        return { valid: true, error: null };
      };
      const validateSelectNetwork = (e) => {
        let NetworkId;
        this.networks.map((el) => {
          if (el.name === e) {
            // console.log(el);
            NetworkId = el.chain_id;
          }
        });
        if (e === "") {
          return { valid: false, error: "Please Select One" };
        } else if (NetworkId !== window.ethereum.networkVersion) {
          return {
            valid: false,
            error: "Please Select the Same Network in MetaMask",
          };
        } else {
          return { valid: true, error: null };
        }
      };
      const validateText = (e) => {
        if (e === "") {
          return { valid: false, error: "This field is required" };
        }
        return { valid: true, error: null };
      };
      const validateDrawAmount = (e) => {
        if (e === "") {
          return { valid: false, error: "This field is required" };
        } else if (e > this.nft.payout) {
          return {
            valid: false,
            error: "Draw Amount Must be lower than Winning Amount",
          };
        } else {
          return { valid: true, error: null };
        }
      };

      this.errors = {};
      // Select sport
      const selectSport = validateSelectSport(this.nft.selectedSport);
      this.errors.selectedSport = selectSport.error;
      if (this.valid) {
        this.valid = selectSport.valid;
      }
      // nft Name

      const nftName = validateText(this.nft.nftName);
      this.errors.nftName = nftName.error;
      if (this.valid) {
        this.valid = nftName.valid;
      }

      // nft symbol
      const nftSymbol = validateText(this.nft.nftSymbol);
      this.errors.nftSymbol = nftSymbol.error;
      if (this.valid) {
        this.valid = nftSymbol.valid;
      }
      // select tournament
      const selectTournament = validateSelectSport(this.nft.selectedTournament);
      this.errors.selectedTournament = selectTournament.error;
      if (this.valid) {
        this.valid = selectTournament.valid;
      }
      // select Network

      const selectedNetWork = validateSelectNetwork(this.nft.selectedNetWork);
      this.errors.selectedNetWork = selectedNetWork.error;
      if (this.valid) {
        this.valid = selectedNetWork.valid;
      }
      const selectedBuyToken = validateSelectSport(this.nft.selectedBuyToken);
      this.errors.selectedBuyToken = selectedBuyToken.error;
      if (this.valid) {
        this.valid = selectedBuyToken.valid;
      }
      // const seletedBuyNetWork = validateSelectSport(this.nft.seletedBuyNetWork);
      // this.errors.seletedBuyNetWork = seletedBuyNetWork.error;
      // if (this.valid) {
      //   this.valid = seletedBuyNetWork.valid;
      // }

      // select team
      const selectTeam = validateSelectSport(this.nft.selectedTeam);
      this.errors.selectedTeam = selectTeam.error;
      if (this.valid) {
        this.valid = selectTeam.valid;
      }

      // select reward token
      const selectRewardToken = validateSelectSport(this.nft.selectedToken);
      this.errors.selectedToken = selectRewardToken.error;
      if (this.valid) {
        this.valid = selectRewardToken.valid;
      }

      // payotut
      const validatePayout1 = validatePayout(this.nft.payout);
      this.errors.payout = validatePayout1.error;
      if (this.valid) {
        this.valid = validatePayout1.valid;
      }
      // nft price
      const validatePrice = validateNftPrice(this.nft.nftPrice);
      this.errors.nftPrice = validatePrice.error;
      if (this.valid) {
        this.valid = validatePrice.valid;
      }

      // Times Nft
      const timesNft = validateTimesNFT(this.nft.timeNftUsed);
      this.errors.timeNftUsed = timesNft.error;
      if (this.valid) {
        this.valid = timesNft.valid;
      }

      // nft for team
      const nftForTeam = validateNftForTeam(this.nft.nftForTeam);
      this.errors.nftForTeam = nftForTeam.error;
      if (this.valid) {
        this.valid = nftForTeam.valid;
      }

      // nft description

      const desc = validateText(this.nft.desc);
      this.errors.desc = desc.error;
      if (this.valid) {
        this.valid = desc.valid;
      }

      // Draw amount
      const drawAmount = validateDrawAmount(this.nft.drawAmount);
      this.errors.drawAmount = drawAmount.error;
      if (this.valid) {
        this.valid = drawAmount.valid;
      }

      if (this.nft.assetUrl === null || this.nft.assetUrl === "") {
        this.errors.assetUrl = "Select one";
        this.valid = false;
      }

      if (this.isThumb) {
        if (this.thumbImage === "" || this.thumbImage === null) {
          this.errors.thumbImage = "Select Thumbnail for video";
          this.valid = false;
        }
      }
      if (this.isGiphy) {
        if (this.lazyImageUrl === "" || this.lazyImageUrl === null) {
          this.errors.lazyImageUrl = "Select Thumbnail for Gif";
          this.valid = false;
        }
      }
      if (
        !this.$store.state.walletAddress ||
        !window.ethereum.selectedAddress
      ) {
        this.$swal("Not Connected", "Connect your metamask Wallet", "error");
        this.valid = false;
      }

      if (this.valid) {
        this.isEnable = false;
        // reward Token
        let rewardToken;
        this.tokens.map((e) => {
          if (e.token_name === this.nft.selectedToken) {
            this.nft.selectedToken = e.id;
            rewardToken = e.contract_address;
          }
        });

        // buy Token
        let buyToken;
        let buyTokenDecimal;
        this.tokens.map((e) => {
          if (e.token_name === this.nft.selectedBuyToken) {
            this.nft.selectedBuyToken = e.id;
            buyToken = e.contract_address;
            buyTokenDecimal = e.decimal;
          }
        });
        if (this.nft.drawAmount === "") {
          this.nft.drawAmount = 0;
        }
        let buyAmountWei = utills.toETHformat(
          this.nft.nftPrice,
          buyTokenDecimal
        );
        let PayoutAmountWei = utills.toETHformat(
          this.nft.payout,
          buyTokenDecimal
        );
        let drawAmountWei = utills.toETHformat(
          this.nft.drawAmount,
          buyTokenDecimal
        );
        // console.log("buyAmountWei", buyAmountWei);
        this.Sports.map((e) => {
          if (e.name === this.nft.selectedSport) {
            this.nft.selectedSport = e.id;
          }
        });
        let factory_contract_address;
        let buy_contract_address;
        this.networks.map((e) => {
          if (e.name === this.nft.selectedNetWork) {
            this.nft.selectedNetWork = e.id;
            factory_contract_address = e.factory_contract_address;
            buy_contract_address = e.buy_contract_address;
          }
        });
        // console.log(
        //   "0x111D46ab385cBc1edAf33902c1b322Fa66fB231F",
        //   factory_contract_address
        // );
        this.Tournaments.map((e) => {
          if (e.name === this.nft.selectedTournament) {
            this.nft.selectedTournament = e.id;
          }
        });
        this.Teams.map((e) => {
          if (e.name === this.nft.selectedTeam) {
            this.nft.selectedTeam = e.id;
          }
        });
        this.loading = true;
        console.log("reeeee");

        let projectId = "2Dtrp7tYuoGdn0ARHBDxOyXPVoz";
        let SecretKey = "97fdcc59de661db2c94bb861420d115a";
        const auth =
          "Basic " + buffer.Buffer.from(projectId + ":" + SecretKey).toString("base64");

        const ipfsAPI = require("ipfs-http-client");
        const ipfs = ipfsAPI.create({
          host: "ipfs.infura.io",
          port: 5001,
          protocol: "https",
          headers: {
            authorization: auth,
          },

        });
        let nftInfo;
        if (this.isThumb) {
          nftInfo = {
            name: this.nft.nftName,
            symbol: this.nft.nftSymbol,
            assetUrl: this.nft.assetUrl,
            thumbImageUrl: this.thumbImage,
          };
        } else {
          nftInfo = {
            name: this.nft.nftName,
            symbol: this.nft.nftSymbol,
            assetUrl: this.nft.assetUrl,
          };
        }

        // console.log("nftInfo", nftInfo);

        const resIpfs = await ipfs.add(buffer.Buffer(JSON.stringify(nftInfo)));
        this.nftJsonUrl = "https://gateway.ipfs.io/ipfs/" + resIpfs.path;
        console.log(this.nftJsonUrl);
        // console.log(
        //   "datas:",
        //   this.$store.state.walletAddress,
        //   this.nft.timeNftUsed,
        //   this.nft.nftForTeam,
        //   this.nft.nftName,
        //   this.nft.nftSymbol,
        //   this.nftJsonUrl,
        //   buyToken,
        //   buyAmountWei,
        //   rewardToken,
        //   PayoutAmountWei,
        //   drawAmountWei,
        //   factory_contract_address,
        //   buyTokenDecimal
        // );
        try {
          const res1 = await factory.create(
            this.$store.state.walletAddress,
            this.nft.timeNftUsed,
            this.nft.nftForTeam,
            this.nft.nftName,
            this.nft.nftSymbol,
            this.nftJsonUrl,
            buyToken,
            // "0x13882eD4FA0bf80e94649d207b454aa5C3eD9163",
            buyAmountWei,
            // "0x13882eD4FA0bf80e94649d207b454aa5C3eD9163",
            rewardToken,
            // this.nft.payout,
            PayoutAmountWei,
            // this.nft.drawAmount,
            drawAmountWei,
            factory_contract_address,
            buyTokenDecimal
          );

          this.$swal("NFT Created", `NFT Created`, "success");
          const imageUrl = !this.isThumb
            ? this.firebaseImageUrl
            : this.firebaseThumbImageUrl;

          const data = {
            tournamentId: this.nft.selectedTournament,
            teamId: this.nft.selectedTeam,
            sport: this.nft.selectedSport,
            nftName: this.nft.nftName,
            rewardAmount: this.nft.payout,
            payoutTokenId: this.nft.selectedToken,
            nftPrice: this.nft.nftPrice,
            usableNft: this.nft.timeNftUsed,
            totalSupply: this.nft.nftForTeam,
            nftSymbol: this.nft.nftSymbol,
            network: this.nft.selectedNetWork,
            ipfsUrl: this.nftJsonUrl,
            buyTokenId: this.nft.selectedBuyToken,
            nftContractAddress: res1.nftContractAddress,
            txnHash: res1.txHash,
            fromAddress: this.$store.state.walletAddress,
            nftDesc: this.nft.desc,
            imageUrl: imageUrl,
            buyContract: buy_contract_address,
            drawAmount: this.nft.drawAmount,
            lazyImageUrl: this.lazyImageUrl,
          };
          console.log("data:", data);
          try {
            // const response = await axios({
            //   url: `${this.sendUrl}/api/users/createNft`,
            //   method: "post",
            //   data: data,
            //   headers: {
            //     Authorization:
            //       "Bearer " + this.$store.state.userCredentials.token,
            //   },
            // });
            const response = await apiService.createNft(data);
            this.loading = false;
            // console.log("final :", this.nftJsonUrl);
            // console.log("response :", response);
            console.log("response", response);
            this.$swal("Success", `NFT Updated`, "success");
            this.resetAll();
          } catch (error) {
            console.log(error);
            this.loading = false;
            // this.resetAll();
          }
        } catch (error) {
          this.isEnable = true;
          console.log("error", error);
          this.loading = false;
          // this.resetAll();
          this.$swal("Failed", `${error}`, "error");
        }
      } else {
        // this.isEnable = false;
      }
    },
    async getNetworks() {
      // const response = await axios({
      //   url: `${this.sendUrl}/api/auth/getNetworks`,
      //   method: "get",
      //   headers: {
      //     Authorization: "Bearer " + this.$store.state.userCredentials.token,
      //   },
      // });
      const response = await apiService.getNetworks();
      // console.log("response networks", response);
      // console.log("new:", "0x111d46ab385cbc1edaf33902c1b322fa66fb231f");
      this.networks = response.data.data.networks;
    },
    async getSports() {
      try {
        // const response = await axios({
        //   url: `${this.sendUrl}/api/auth/getSports`,
        //   method: "get",
        //   headers: {
        //     Authorization: "Bearer " + this.$store.state.userCredentials.token,
        //   },
        // });
        const response = await apiService.getSports();
        // console.log("sports", response);
        this.Sports = response.data.data.sports;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error.message);
      }
    },
    async onSportsChange($event) {
      this.Tournaments = [];
      this.loading = true;
      let getId;
      this.Sports.map((e) => {
        if (e.name === $event.target.value) {
          getId = e.id;
        }
      });
      if (getId) {
        try {
          // const response = await axios({
          //   url: `${this.sendUrl}/api/auth/getToursAdmin`,
          //   params: {
          //     sportId: getId,
          //   },
          //   method: "get",
          //   headers: {
          //     Authorization:
          //       "Bearer " + this.$store.state.userCredentials.token,
          //   },
          // });
          const response = await apiService.getTournamentsById(getId);
          this.Tournaments = response.data.data.tournaments;
          this.loading = false;
        } catch (error) {
          this.loading = false;
          console.log("error", error);
        }
      }
    },
    async onTournamentChange($event) {
      this.Teams = [];
      this.loading = true;
      let getId;
      this.Tournaments.map((e) => {
        if (e.name === $event.target.value) {
          getId = e.id;
        }
      });
      if (getId) {
        try {
          // const response = await axios({
          //   url: `${this.sendUrl}/api/auth/getTeams/${getId}`,
          //   method: "get",
          //   headers: {
          //     Authorization:
          //       "Bearer " + this.$store.state.userCredentials.token,
          //   },
          // });
          const teams = await apiService.getTeamById(getId);
          this.Teams = teams;
          this.loading = false;
        } catch (error) {
          this.loading = false;
          console.log("error", error);
        }
      }
    },
    async getTokensByNetwork($event) {
      this.tokens = [];
      this.loading = true;
      try {
        let getId;
        this.networks.map((e) => {
          if (e.name === $event.target.value) {
            getId = e.id;
          }
        });
        if (getId) {
          // const response = await axios({
          //   url: `${this.sendUrl}/api/auth/getTokens/${getId}`,
          //   method: "get",
          //   // params: {
          //   //   network: ,
          //   // },
          //   headers: {
          //     Authorization:
          //       "Bearer " + this.$store.state.userCredentials.token,
          //   },
          // });
          const response = await apiService.getTokensByNetwork(getId);
          this.tokens = response.data.data.tokens;
          this.loading = false;
        }
      } catch (error) {
        this.loading = false;
        console.log("error", error);
      }
    },
    calcWin(values) {
      // console.log("vales", this.nft.payout);
      if (
        this.nft.timeNftUsed &&
        this.nft.payout &&
        this.nft.nftForTeam &&
        this.nft.timeNftUsed != "" &&
        this.nft.nftForTeam != ""
      ) {
        this.winAmount = (
          this.nft.timeNftUsed *
          this.nft.payout *
          this.nft.nftForTeam
        ).toFixed(2);
      } else {
        this.winAmount = null;
      }
    },
  },
  mounted() {
    this.checkConnectedWalletExist();
  },
  created() {
    this.getNetworks();
    this.getSports();

    // console.log("router", this.$router);
  },
};
</script>

<style scoped>
body {
  font: 15px/1.3 "Open Sans", sans-serif;
  color: #5e5b64;
  text-align: center;
  background: #3494e6;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #ec6ead, #3494e6);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #ec6ead, #3494e6);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

#main {
  max-height: max-content;
  min-height: 100vh;
  /* background: #c9d6ff;
  background: -webkit-linear-gradient(to right, #e2e2e2, #c9d6ff);
  background: linear-gradient(to right, #e2e2e2, #c9d6ff); */

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

/* .meta-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
} */

a,
a:visited {
  outline: none;
  color: #389dc1;
}

a:hover {
  text-decoration: none;
}

section,
footer,
header,
aside,
nav {
  display: block;
}

.walletAdd {
  color: #4f4f4f;
  font-size: 18px;
}

/* forms */
.form-control {
  /* height: 48px;
  border: 2px solid rgba(202, 202, 202, 0.6);
  border-radius: 12px; */
}

input[type="text"],
input[type="number"] {
  height: 48px;
  border: 2px solid rgba(202, 202, 202, 0.6);
  border-radius: 12px;
}

.textArea {
  height: none;
}

.form-control:focus {
  box-shadow: none;
  border: 2px solid #039be5;
}

.form-select {
  color: #4f4f4f;
  height: 48px;
  border: 2px solid rgba(219, 219, 219, 0.6);
  border-radius: 12px;
}

/* input[type="file"] {
  padding: 10px 15px;
} */
input[type="file"]::file-selector-button {
  border: 2px solid #d4d4d4;
  padding: 0.2em 0.4em;
  border-radius: 12px;
  background-color: rgb(238, 238, 238);
  transition: 1s;
}

/* input[type="file"]::file-selector-button:hover {
  background-color: #81ecec;
  border: 2px solid #00cec9;
} */

/*-------------------------
	The menu
--------------------------*/

nav {
  display: inline-block;
  margin: 60px auto 45px;
  background-color: #5597b4;
  box-shadow: 0 1px 1px #ccc;
  border-radius: 2px;
}

nav a {
  display: inline-block;
  padding: 18px 30px;
  color: #fff !important;
  font-weight: bold;
  font-size: 16px;
  text-decoration: none !important;
  line-height: 1;
  text-transform: uppercase;
  background-color: transparent;

  -webkit-transition: background-color 0.25s;
  -moz-transition: background-color 0.25s;
  transition: background-color 0.25s;
}

nav a:first-child {
  border-radius: 2px 0 0 2px;
}

nav a:last-child {
  border-radius: 0 2px 2px 0;
}

nav.home .home,
nav.projects .projects,
nav.services .services,
nav.contact .contact {
  background-color: #e35885;
}

p {
  font-size: 22px;
  font-weight: bold;
  color: #7d9098;
}

p b {
  color: #ffffff;
  display: inline-block;
  padding: 5px 10px;
  background-color: #c4d7e0;
  border-radius: 2px;
  text-transform: uppercase;
  font-size: 18px;
}

.Title {
  font-weight: 700;
  text-align: start;
}

.align-left {
  display: flex;
  align-items: center;
  justify-content: start;
}

.btn-rounded {
  border-radius: 25px;
  padding: 10px 0;
  width: 200px !important;
}

.wrapper {
  height: 100vh;
  background: #2980b9;
  background: -webkit-linear-gradient(to right, #ffffff, #6dd5fa, #2980b9);
  background: linear-gradient(to right, #ffffff, #6dd5fa, #2980b9);
}

.btn {
  width: 40%;
}

.preview {
  position: relative;
  margin-top: 20px;
  width: 250px;
  padding: 10px;

  /* border: 1px solid #000; */
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

.preview img {
  width: 100%;
}

.preview video {
  width: 100%;
}

audio {
  width: 100%;
}

.progress-container {
  position: absolute;
  top: 50%;
  left: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
}

.upload span {
  color: white;
}

.isUpload {
  display: flex;
}

.progress-container span {
  color: #4f4f4f;
}

.error-msg {
  text-align: start;
  color: #dc3545;
}

/* CSS */
.button-71 {
  background-color: #0078d0;
  border: 0;
  border-radius: 56px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: system-ui, -apple-system, system-ui, "Segoe UI", Roboto, Ubuntu,
    "Helvetica Neue", sans-serif;
  font-size: 18px;
  font-weight: 600;
  outline: 0;
  padding: 5px 21px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-71:before {
  background-color: initial;
  background-image: linear-gradient(#fff 0, rgba(255, 255, 255, 0) 100%);
  border-radius: 125px;
  content: "";
  height: 50%;
  left: 4%;
  opacity: 0.5;
  position: absolute;
  top: 0;
  transition: all 0.3s;
  width: 92%;
}

.label {
  display: flex;
  align-items: center;
  justify-content: start;
}

.label span {
  padding-left: 10px !important;
  margin-bottom: 8px;
}

.win-calc {
  width: 100%;
  display: flex;
  justify-content: start;
  margin-left: 10px;
}

.disableBtn {
  opacity: 0.5;
}

.button-71:hover {
  box-shadow: rgba(255, 255, 255, 0.2) 0 3px 15px inset,
    rgba(0, 0, 0, 0.1) 0 3px 5px, rgba(0, 0, 0, 0.1) 0 10px 13px;
  transform: scale(1.05);
}

@media (min-width: 768px) {
  .button-71 {
    padding: 16px 48px;
  }
}

.spinner {
  height: 100vh;
  width: 80vw;
  top: 0;
  background-color: rgba(255, 255, 255, 0);
  opacity: 1;
  z-index: 1;
}

.spinner-border {
  border: 0.25em solid #11101d;
  border-right-color: transparent;
  border-radius: 50%;
}

.winam {
  color: green;
  border: 2px solid green;
  cursor: pointer;
  text-align: center;
  font-weight: 900;
  font-size: 20px;
}

.winlabel {
  color: green;
  font-weight: 800;
}
</style>