<template>
  <div class="main">
    <div class="algncnt row" v-if="!loading">
      <div class="datepicker col-lg-6 my-2" v-if="!loading">
        <DatePicker
          v-model="daterange"
          type="date"
          range
          confirm
          @confirm="getTournaments()"
          format="DD/MM/YYYY"
          placeholder="Select date range"
        ></DatePicker>
      </div>

      <div class="col-6 my-2 btn-align">
        <button
          v-if="filterdata"
          type="button"
          class="btn btn-secondary"
          @click="clearFilter()"
        >
          Clear all
        </button>
      </div>
      <div class="col-lg-12 col-6 my-2 btn-align d-flex justify-content-end">
        <!-- <vue-json-to-csv :json-data="Tournaments" :csv-title="'Tournaments'">
          <b-button> Download CSV </b-button>
        </vue-json-to-csv> -->
        <b-button @click="downloadCSV()"> Download CSV </b-button>
      </div>
    </div>

    <br />
    <div class="loadoverlay" v-if="loading">
      <b-spinner></b-spinner>
    </div>
    <p v-if="!loading && isdata" class="nodata">
      No Data Available at the moment.
    </p>

    <div class="datatable" v-if="!loading && !isdata">
      <b-table
        hover
        :fields="fields"
        :items="Tournaments"
        :per-page="perPage"
        no-sort-reset
        responsive="sm"
      >
        <template #cell(btn)="data">
          <b-button @click="rowdata(data)" size="sm"> click </b-button>
        </template>
      </b-table>
      <b-pagination
        @change="getTournaments"
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="my-table"
        pills
      ></b-pagination>
      <!-- <b-pagination
        v-model="current"
        :total-rows="row"
        :per-page="per"
        aria-controls="my-table"
        pills
      ></b-pagination> -->
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import axios from "axios";
import moment from "moment";
import VueJsonToCsv from "vue-json-to-csv";
import { apiService } from "@/services/apiService";
import { utills } from "@/services/utill";

export default {
  name: "tbview",
  data() {
    return {
      daterange: [],
      perPage: 12,
      currentPage: 1,
      isdata: false,
      loading: true,
      // row: 33,
      // current: 2,
      // per: 2,
      totalPages: 1,

      fields: [
        {
          key: "id",
          label: "ID",
          color: "red",
        },
        {
          key: "name",
          label: "Name",
        },
        {
          key: "sport_name",
          label: "Sport Name",
        },
        {
          key: "sport_id",
          label: "Sport ID",
        },
        {
          key: "start_date",
          label: "Start Date",
        },
        {
          key: "end_date",
          label: "End Date",
        },
        // {
        //   key: "btn",
        //   label: "Button",
        // },
      ],
      Tournaments: [],
      // sendUrl: "https://1a12-118-185-191-21.ngrok.io",
      sendUrl: apiService.getUrl(),
    };
  },
  components: {
    VueJsonToCsv,
    DatePicker,
  },
  computed: {
    rows() {
      return this.Tournaments.length * this.totalPages;
    },
    filterdata() {
      if (this.daterange == null || this.daterange.length == 0) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    // filter() {},
    // async getClickedPage(e) {
    //   try {
    //     console.log("event ", e);
    //     this.currentPage = e;
    //     this.loading = true;

    //     // const res = await apiService.getTournamentsbyPage(e);
    //     const res = await apiService.getTournaments(e);

    //     // console.log("respmf", res);
    //     this.Tournaments = [];

    //     res.data.data.tournaments.forEach((e) => {
    //       const data = {
    //         end_date: moment(e.end_date).format("DD-MM-YYYY , h:mm"),
    //         start_date: moment(e.start_date).format("DD-MM-YYYY, h:mm"),
    //         id: e.id,
    //         sport_name: e.sport_name,
    //         sport_id: e.sport_id,
    //         name: e.name,
    //       };
    //       this.Tournaments.push(data);
    //     });
    //     this.loading = false;
    //   } catch (error) {
    //     console.log(error.message);
    //     this.loading = false;
    //     this.isdata = true;
    //   }
    // },
    async getTournaments(e) {
      try {
        // console.log("enterd");
        if (e) {
          this.currentPage = e;
          this.Tournaments = [];
          this.loading = true;
        }

        let from = null;
        let to = null;
        if (this.daterange.length > 0) {
          from = this.daterange[0].getTime();
          to = this.daterange[1].getTime();
          this.Tournaments = [];
          this.loading = true;
        }

        const tournaments = await apiService.getTournaments(
          this.currentPage,
          from,
          to
        );
        // console.log("response", tournaments);
        tournaments.data.data.tournaments.forEach((e) => {
          const data = {
            end_date: moment.utc(e.end_date).format("DD-MM-YYYY-h:mm-A"),
            start_date: moment.utc(e.start_date).format("DD-MM-YYYY-h:mm-A"),
            id: e.id,
            sport_name: e.sport_name,
            sport_id: e.sport_id,
            name: e.name,
          };
          this.Tournaments.push(data);
        });
        this.totalPages = tournaments.data.data.totalPages;

        //  Check for Length for pagination
        if (this.Tournaments.length < 12) {
          this.perPage = this.Tournaments.length;
        } else {
          this.perPage = 12;
        }

        if (!this.Tournaments.length > 0) {
          this.isdata = true;
        }
        this.loading = false;
      } catch (error) {
        console.log(error.message);
        this.loading = false;
        this.isdata = true;
      }
    },
    rowdata(data) {
      // console.log(data.item);
    },
    async downloadCSV() {
      let from = null;
      let to = null;
      console.log("daterange", this.daterange);
      if (this.daterange.length > 0) {
        from = this.daterange[0].getTime();
        to = this.daterange[1].getTime();
      }
      console.log(this.totalPages);
      const items = this.Tournaments.length * this.totalPages;
      console.log("items", items);
      try {
        const tournaments = await apiService.getTournaments(
          this.currentPage,
          from,
          to,
          items
        );
        console.log("matches", tournaments);
        let csvData = [];
        tournaments.data.data.tournaments.forEach((e) => {
          csvData.push({
            end_date: moment.utc(e.end_date).format("DD-MM-YYYY-h:mm-A"),
            start_date: moment.utc(e.start_date).format("DD-MM-YYYY-h:mm-A"),
            id: e.id,
            sport_name: e.sport_name,
            sport_id: e.sport_id,
            name: e.name,
          });
        });
        utills.downloadCSV("Tournaments.csv", csvData);
      } catch (error) {
        console.log(error);
      }
    },
    async clearFilter() {
      window.location.reload();
    },
  },
  created() {
    this.getTournaments();
  },
};
</script>

<style>
.algncnt {
  text-align: start !important;
  /* float: left !important; */
}
.main {
  width: 80%;
  margin: auto;
  margin-top: 5vh;
}
</style>