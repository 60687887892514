<template>
  <div>
    <div class="main">
      <div class="algncnt table-header row" v-if="!loading">
        <!-- <div class="filter-content col"> -->
        <div class="col-4 my-2">
          <!-- <div class="label">
            <span>Filter by Status :</span>
          </div> -->
          <select
            class="form-select"
            label="Default select example"
            v-model="selectedStatus"
            @change="getCompleteMatches()"
          >
            <option value="null" disabled>Select by Status</option>
            <option
              v-for="st in status"
              v-bind:value="st.status_str"
              v-bind:key="st.status_str"
            >
              {{ st.status_str }}
            </option>
          </select>
        </div>
        <div class="col-4 my-2">
          <!-- <div class="label">
            <span>Filter by Sports :</span>
          </div> -->
          <select
            class="form-select"
            label="Default select example"
            v-model="selectedSport"
            @change="getCompleteMatches()"
          >
            <option value="null" disabled>Filter by Sports</option>
            <option
              v-for="sport in sports"
              v-bind:value="sport"
              v-bind:key="sport.name"
            >
              {{ sport.name }}
            </option>
          </select>
        </div>

        <div class="col-4 my-2">
          <!-- <div class="label">
            <span>Filter by Tournament :</span>
          </div> -->
          <select
            class="form-select"
            label="Default select example"
            v-model="selectedTournament"
            @change="getCompleteMatches()"
          >
            <option value="null" disabled>Select a Tournament</option>
            <option
              v-for="tour in tournament"
              v-bind:value="tour"
              v-bind:key="tour.name"
            >
              {{ tour.name }}
            </option>
          </select>
        </div>
        <div class="datepicker col-4 my-2">
          <!-- <div class="label">
            <span>Filter By Date Range:</span>
          </div> -->
          <DatePicker
            v-model="daterange"
            type="date"
            range
            confirm
            @confirm="getCompleteMatches()"
            placeholder="Select date range"
          ></DatePicker>
        </div>
        <div class="col-6 my-2" v-if="filterdata">
          <button
            type="button"
            class="btn btn-secondary"
            @click="clearFilter()"
          >
            Clear all
          </button>
        </div>
        <div class="col-6 my-2 d-flex justify-content-start">
          <!-- <vue-json-to-csv
            v-if="!loading"
            :json-data="completed"
            :csv-title="'NFT Usage'"
          >
            <b-button> Download CSV </b-button>
          </vue-json-to-csv> -->
          <b-button @click="downloadCSV()"> Download CSV </b-button>
        </div>

        <!-- </div> -->
      </div>

      <br />
      <div class="loadoverlay" v-if="loading">
        <b-spinner v-if="loading"></b-spinner>
        <!-- <p v-if="completed.length < 1" class="nodata">
        No Data Available at the moment.
      </p> -->
      </div>

      <div v-if="completed.length > 0">
        <b-table
          table-class="text-nowrap"
          hover
          :fields="fields"
          :items="completed"
          :per-page="perPage"
          outlined
          no-sort-reset
          responsive
        >
        </b-table>
        <b-pagination
          v-model="currentPage"
          @change="getCompleteMatches"
          :total-rows="rows"
          :per-page="perPage"
          :page="page"
          aria-controls="my-table"
          pills
        ></b-pagination>
      </div>
      <div v-if="!loading && !completed.length > 0" class="nodata">
        <p>No Data Available at the moment.</p>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import axios from "axios";
import moment from "moment";
import VueJsonToCsv from "vue-json-to-csv";
import { apiService } from "@/services/apiService";
import { utills } from "@/services/utill";

export default {
  name: "Completed",
  components: {
    VueJsonToCsv,
    DatePicker,
  },
  data() {
    return {
      sendUrl: apiService.getUrl(),
      // sendUrl: this.$store.state.apiUrl,
      daterange: [],
      completed: [],
      perPage: 12,
      // rows: 5,
      currentPage: 1,
      loading: true,
      page: 5,
      tours: [],
      selectedTournament: null,
      tournament: [],
      sports: [],
      selectedSport: null,
      status: [],
      selectedStatus: null,
      totalPages: 1,
      fields: [
        {
          key: "match_id",
          label: "id",
          color: "red",
        },
        {
          key: "match_name",
          label: "Match Name",
        },
        {
          key: "tour_name",
          label: "Tournament Name",
        },
        {
          key: "match_start_time",
          label: "match_start_time",
        },

        {
          key: "matchstatus",
          label: "matchstatus",
        },
      ],
    };
  },
  computed: {
    rows() {
      return this.completed.length * this.totalPages;
    },
    filterdata() {
      if (
        this.daterange == null ||
        (this.daterange.length == 0 &&
          this.selectedSport == null &&
          this.selectedTournament == null &&
          this.selectedStatus == null)
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    async getCompleteMatches(e) {
      try {
        this.completed = [];
        this.loading = true;
        if (e) {
          this.currentPage = e;
        }
        let from = null;
        let to = null;
        let status = null;
        let bySport = null;
        let byTour = null;
        if (this.daterange.length > 0) {
          from = this.daterange[0].getTime();
          to = this.daterange[1].getTime();
        }
        if (this.selectedStatus) {
          status = this.selectedStatus;
        }
        if (this.selectedSport) {
          // console.log("this.selectedSport", this.selectedSport);
          bySport = this.selectedSport.sport;
        }
        if (this.selectedTournament) {
          byTour = this.selectedTournament.id;
        }
        const response = await apiService.getCompleteMatches(
          this.currentPage,
          from,
          to,
          status,
          bySport,
          byTour
        );
        // this.Tournaments= response.data.data.tournaments;
        console.log("response xompleted", response.data);
        this.tours = response.data.data.tours;
        response.data.data.matches.forEach((e) => {
          const data = {
            match_id: e.match_id,
            match_name: e.match_name,
            match_start_time: moment
              .utc(e.match_start_time)
              .format("DD-MM-YYYY-h:mm-A"),
            // match_start_time: e.match_start_time,
            tour_name: e.tour_name,
            matchstatus: e.matchstatus,
          };
          this.completed.push(data);
          this.rows = response.data.data.totalPage;
          // console.log(moment(e.end_date).format("YYYY-MM-DD"));
        });
        this.tournament = response.data.data.tours;
        this.sports = response.data.data.sport;
        this.status = response.data.data.status;
        this.totalPages = response.data.data.totalPage;
        //  Check for Length for pagination
        if (this.completed.length < 12) {
          this.perPage = this.completed.length;
        } else {
          this.perPage = 12;
        }

        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    async downloadCSV() {
      let from = null;
      let to = null;
      let status = null;
      let bySport = null;
      let byTour = null;
      if (this.daterange.length > 0) {
        from = this.daterange[0].getTime();
        to = this.daterange[1].getTime();
      }
      if (this.selectedStatus) {
        status = this.selectedStatus;
      }
      if (this.selectedSport) {
        bySport = this.selectedSport.sport;
      }
      if (this.selectedTournament) {
        byTour = this.selectedTournament.id;
      }

      const items = this.completed.length * this.totalPages;
      const csvdata = [];
      try {
        const response = await apiService.getCompleteMatches(
          this.currentPage,
          from,
          to,
          status,
          bySport,
          byTour,
          items
        );
        response.data.data.matches.forEach((e) => {
          const data = {
            match_id: e.match_id,
            match_name: e.match_name,
            match_start_time: moment
              .utc(e.match_start_time)
              .format("DD-MM-YYYY-h:mm-A"),
            // match_start_time: e.match_start_time,
            tour_name: e.tour_name,
            matchstatus: e.matchstatus,
          };
          csvdata.push(data);
        });

        utills.downloadCSV("Completed Matches.csv", csvdata);
      } catch (error) {
        console.log(error);
      }
    },
    async clearFilter() {
      window.location.reload();
    },
  },
  created() {
    this.getCompleteMatches();
  },
};
</script>

<style>
/* .nodata {
  color: red;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
} */
/* .filter-content {
  display: flex;
  align-items: end;
} */
/* .loadoverlay {
  position: fixed;
  height: 80vh;
  width: 65vw;
  display: flex;
  align-items: center;
  justify-content: center;
} */
/* .table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
} */
</style>