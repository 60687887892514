<template>
  <div>
    <b-navbar dark>
      <b-navbar-brand tag="h3">{{ currentRouteName }}</b-navbar-brand>
      <b-navbar-nav class="ml-auto">
        <b-button
          right
          variant="primary"
          v-if="!$store.state.walletAddress"
          type="button"
          class="ml-auto"
          pill
          @click="connectMeta()"
        >
          Connect MetaMask
        </b-button>
        <p class="walletAdd" v-else>{{ walletAddress }}</p>
      </b-navbar-nav>
    </b-navbar>
  </div>
</template>

<script>
import { ethers } from "ethers";
// import { store } from "../store/index";

export default {
  name: "NavBar",
  data() {
    return {
      pagehead: "header",
      isWalletAddress: false,
    };
  },
  watch: {
    disconnectWallet() {
      if (window.ethereum.selectedAddress) {
        // console.log("sdfsfsdfsfsef");
        this.$store.dispatch("deleteWalletAddress");
      }
    },
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    walletAddress() {
      if (!this.$store.state.walletAddress) {
        this.connectMeta();
      }
      return this.$store.state.walletAddress;
    },
  },
  methods: {
    async connectMeta() {
      if (typeof window.ethereum !== "undefined") {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const acc = await provider.send("eth_requestAccounts", []);
        if (acc.length > 0) {
          this.isWalletAddress = true;
          this.$store.dispatch("setWalletAddress", acc[0]);
        } else {
          this.$swal("Missing", `MetaMask Not Connected`, "error");
        }
      } else {
        this.$swal("Missing", `MetaMask not installed`, "error");
      }
    },
    listenAccountChange() {
      // console.log("caleed");
      const self = this;
      if (window.ethereum !== undefined) {
        window.ethereum.on("accountsChanged", function (accounts) {
          self.$store.dispatch("deleteWalletAddress");

          window.location.reload();
          // console.log("accounts", accounts);
          // if (accounts.length > 0) {
          //   // console.log("accounts", accounts);
          //   this.$store.dispatch("setWalletAddress", accounts[0]);

          //   // localStorage.setItem("connected", true);
          // }
        });
      }
    },
  },
  // mounted() {
  //   console.log("mounted", $store.state.walletAddress);
  //   if (!$store.state.walletAddress) {
  //     this.connectMeta();
  //   }
  // },
  created() {
    // console.log("created");
    // console.log("this.$store.state.walletAddress", this.$store);
    // if (!this.$store.state.walletAddress) {
    //   console.log("enterd");
    //   this.connectMeta();
    // }
    this.listenAccountChange();
  },
};
</script>

<style>
.navbar {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: space-between !important;
}
.walletAdd {
  color: #4f4f4f;
  font-size: 18px;
}
</style>