<template>
  <div class="main">
    <div class="algncnt table-header row" v-if="!loading">
      <!-- <div class="col-4">
        <vue-json-to-csv
          v-if="!loading"
          :json-data="upcomming"
          :csv-title="'NFT Usage'"
        >
          <b-button> Download CSV </b-button>
        </vue-json-to-csv>
      </div> -->

      <!-- <div class="filter-content"> -->
      <div class="col-4 my-2">
        <!-- <div class="label">
          <span>Filter by Status :</span>
        </div> -->
        <select
          class="form-select"
          label="Default select example"
          v-model="selectedStatus"
          @change="getUpcommingMatches()"
        >
          <option value="null" disabled>Select by Status</option>
          <option
            v-for="st in status"
            v-bind:value="st.status_str"
            v-bind:key="st.status_str"
          >
            {{ st.status_str }}
          </option>
        </select>
      </div>
      <div class="col-4 my-2">
        <!-- <div class="label">
          <span>Filter by Sports :</span>
        </div> -->
        <select
          class="form-select"
          label="Default select example"
          v-model="selectedSport"
          @change="getUpcommingMatches()"
        >
          <option value="null" disabled>Select a Sport</option>
          <option
            v-for="sport in sports"
            v-bind:value="sport"
            v-bind:key="sport.sports_name"
          >
            {{ sport.sports_name }}
          </option>
        </select>
      </div>
      <div class="col-4 my-2">
        <!-- <div class="label">
          <span>Filter by Tournament :</span>
        </div> -->
        <select
          class="form-select"
          label="Default select example"
          v-model="selectedTournament"
          @change="getUpcommingMatches()"
        >
          <option value="null" disabled>Filter by Tournament</option>
          <option
            v-for="tour in tournament"
            v-bind:value="tour"
            v-bind:key="tour.tournament_name"
          >
            {{ tour.tournament_name }}
          </option>
        </select>
      </div>
      <div class="col-4 my-2">
        <DatePicker
          v-model="daterange"
          type="date"
          range
          confirm
          @confirm="getUpcommingMatches()"
          placeholder="Select date range"
          style="float: left"
        ></DatePicker>
      </div>
      <div class="col-4 my-2 d-flex justify-content-end">
        <button
          type="button"
          v-if="filterdata"
          class="btn btn-secondary"
          @click="clearFilter()"
        >
          Clear all
        </button>
      </div>
      <div class="col-lg-4 col-6 d-flex justify-content-lg-end">
        <!-- <vue-json-to-csv
          v-if="!loading"
          :json-data="upcomming"
          :csv-title="'NFT Usage'"
        > -->
        <b-button @click="downloadCSV()"> Download CSV </b-button>
        <!-- </vue-json-to-csv> -->
      </div>

      <!-- <div class="col-4 d-flex justify-content-end">
        <vue-json-to-csv
          v-if="!loading"
          :json-data="upcomming"
          :csv-title="'NFT Usage'"
        >
          <b-button> Download CSV </b-button>
        </vue-json-to-csv>
      </div> -->

      <!-- </div> -->
    </div>
    <br />
    <div class="loadoverlay" v-if="loading">
      <b-spinner v-if="loading"></b-spinner>
      <!-- <p v-if="upcomming.length < 1" class="nodata">
        No Data Available at the moment.
      </p> -->
    </div>

    <div v-if="upcomming.length > 0">
      <b-table
        table-class="text-nowrap"
        hover
        :fields="fields"
        :items="upcomming"
        :per-page="perPage"
        striped
        outlined
        no-sort-reset
        responsive
      >
        <template #cell(start_time)="data">
          <span>{{ FormatDate(data.item.start_time) }}</span>
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        @change="getUpcommingMatches"
        :total-rows="rows"
        :per-page="perPage"
        :page="page"
        aria-controls="my-table"
        pills
      ></b-pagination>
    </div>
    <div v-if="!loading && !upcomming.length > 0" class="nodata">
      <p>No Data Available at the moment.</p>
    </div>
    <!-- <b-button v-b-modal.modal-center>Launch centered modal</b-button>

    <b-modal id="modal-center" centered title="BootstrapVue">
      <p class="my-4">Vertically centered modal!</p>
    </b-modal> -->
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import VueJsonToCsv from "vue-json-to-csv";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { apiService } from "@/services/apiService";
import { utills } from "@/services/utill";
// import ObjectsToCsv from "objects-to-csv";
export default {
  data() {
    return {
      upcomming: [],
      daterange: [],
      perPage: 12,
      currentPage: 1,
      loading: true,
      isdata: false,
      page: 5,
      tours: [],
      totalPages: 1,
      tours: [],
      selectedTournament: null,
      tournament: [],
      sports: [],
      selectedSport: null,
      status: [],
      selectedStatus: null,
      fields: [
        {
          key: "id",
          label: "id",
          color: "red",
        },
        {
          key: "match_name",
          label: "Match Name",
        },
        {
          key: "start_time",
          label: "Start Time",
        },
        {
          key: "tournament_name",
          label: "Tournament Name",
        },
        {
          key: "sport_name",
          label: "sport_name",
        },
        {
          key: "status_str",
          label: "Match Status",
        },
        // {
        //   key: "token_name",
        //   label: "Buy Token",
        // },

        // {
        //   key: "is_nft_active",
        //   label: "is Active",
        // },
        // {
        //   key: "btn",
        //   label: "View",
        // },
      ],
    };
  },
  components: {
    VueJsonToCsv,
    DatePicker,
  },
  computed: {
    rows() {
      return this.upcomming.length * this.totalPages;
    },
    filterdata() {
      if (
        this.selectedTournament == null &&
        this.selectedSport == null &&
        this.selectedStatus == null &&
        this.daterange.length == 0
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    async downloadCSV() {
      let status = null;
      let bySport = null;
      let byTour = null;
      let from = null;
      let to = null;
      if (this.selectedStatus) {
        status = this.selectedStatus;
      }
      if (this.selectedSport) {
        // console.log("this.selectedSport", this.selectedSport);
        bySport = this.selectedSport.sports_id;
      }
      if (this.selectedTournament) {
        byTour = this.selectedTournament.tournaments_id;
      }
      if (this.daterange > 0) {
        from = this.daterange[0].getTime();
        to = this.daterange[1].getTime();
      }

      const items = this.upcomming.length * this.totalPages;

      try {
        const response = await apiService.getUpcommingMatches(
          this.currentPage,
          byTour,
          from,
          to,
          bySport,
          status,
          items
        );
        let csvData = [];
        response.data.data.matches.forEach((element) => {
          csvData.push({
            id: element.id,
            match_name: element.match_name,
            start_time: moment
              .utc(element.start_time)
              .format("DD-MM-YYYY-h:mm-A"),
            tournament_name: element.tournament_name,
            sport_name: element.sport_name,
            status_str: element.status_str,
          });
        });
        // console.log("matches", response);
        utills.downloadCSV("upcoming.csv", csvData);
      } catch (error) {
        console.log(error);
      }
    },

    clearFilter() {
      window.location.reload();
    },
    FormatDate(data) {
      return moment.utc(data).format("DD-MM-YYYY-h:mm-A");
    },
    async getUpcommingMatches(e) {
      try {
        this.upcomming = [];
        this.loading = true;
        if (e) {
          this.currentPage = e;
        }
        let from = null;
        let to = null;
        let status = null;
        let bySport = null;
        let byTour = null;

        console.log("this.daterange", this.daterange);

        if (this.daterange.length > 0) {
          from = this.daterange[0].getTime();
          to = this.daterange[1].getTime();
        }
        // console.log("from", "to", from, to);
        if (this.selectedStatus) {
          status = this.selectedStatus;
        }
        if (this.selectedSport) {
          // console.log("this.selectedSport", this.selectedSport);
          bySport = this.selectedSport.sports_id;
        }
        if (this.selectedTournament) {
          byTour = this.selectedTournament.tournaments_id;
        }
        const response = await apiService.getUpcommingMatches(
          this.currentPage,
          from,
          to,
          byTour,
          bySport,
          status
        );
        // console.log("matches", response);
        this.upcomming = response.data.data.matches;
        this.tournament = response.data.data.tourQuery;
        this.status = response.data.data.statusQuery;
        this.sports = response.data.data.sportQuery;
        this.totalPages = response.data.data.totalPage;

        // console.log("totosl", this.totalPages);
        if (this.upcomming.length < 12) {
          this.perPage = this.upcomming.length;
        } else {
          this.perPage = 12;
        }
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
  },

  created() {
    this.getUpcommingMatches();
  },
};
</script>

<style>
</style>