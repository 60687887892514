import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import router from '../router/index'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userCredentials: null,
    walletAddress: null,
    // apiUrl: "https://prediction-api.rage.fan",
    apiUrl: "https://b50f-118-185-191-21.ngrok.io"
  },
  getters: {
    getUserDetails: (state) => state.userCredentials

  },
  mutations: {
    SET_USER_CREDENTIALS(state, payload) {
      state.userCredentials = payload;
    },
    logout: (state) => {
      state.userCredentials = null;
    },
    setWalletAddress(state, payload) {
      state.walletAddress = payload;
    },
    deleteWalletAddress(state) {
      // console.log("deleted");
      // if (state) {
      state.walletAddress = null
      // }
    }
  },
  actions: {
    setUserCredentials(context, payload) {
      localStorage.setItem("authToken", JSON.stringify(payload));
      context.commit("SET_USER_CREDENTIALS", payload);
    },
    fetchAccessToken({ commit }) {
      commit('SET_USER_CREDENTIALS', JSON.parse(localStorage.getItem("authToken")));
    },
    logout({ commit }) {
      localStorage.removeItem('authToken');
      commit('logout');
      router.push({ name: "home" });
      window.location.reload()
    },
    setWalletAddress(context, payload) {
      context.commit("setWalletAddress", payload);
    },
    deleteWalletAddress({ commit }) {
      commit('deleteWalletAddress')
    }
  },
  modules: {
  },
  plugins: [createPersistedState()],
})
