import { formatUnits, parseUnits } from "ethers/lib/utils"

export const utills = {
    format(value, decimal) {
        return parseFloat(formatUnits(value, decimal));
    },
    toETHformat(value, decimal) {
        return parseUnits(value.toString(), decimal);
    },
    convertArrayOfObjectsToCSV(args) {
        var result, ctr, keys, columnDelimiter, lineDelimiter, data;

        data = args.data || null;
        if (data == null || !data.length) {
            return null;
        }

        columnDelimiter = args.columnDelimiter || ",";
        lineDelimiter = args.lineDelimiter || "\n";

        keys = Object.keys(data[0]);

        result = "";
        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        data.forEach(function (item) {
            ctr = 0;
            keys.forEach(function (key) {
                if (ctr > 0) result += columnDelimiter;

                result += item[key];
                ctr++;
            });
            result += lineDelimiter;
        });

        return result;
    },
    downloadCSV(fileName = "export.csv", obj) {
        let data, filename, link;
        let csv = this.convertArrayOfObjectsToCSV({
            data: obj,
        });
        if (csv == null) return;

        filename = fileName;

        if (!csv.match(/^data:text\/csv/i)) {
            csv = "data:text/csv;charset=utf-8," + csv;
        }
        data = encodeURI(csv);

        link = document.createElement("a");
        link.setAttribute("href", data);
        link.setAttribute("download", filename);
        link.click();
    },

}