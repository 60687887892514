const apiUrl = "https://prediction-api.rage.fan";

// const apiUrl = "https://1933-118-185-191-21.ngrok.io"

import axios from "axios";
import store from "@/store";

// const token = store.state.userCredentials.token;

export const apiService = {
    getUrl() {
        return apiUrl;
    },
    async loginData(data) {
        // console.log("store", store);
        const response = await axios({
            url: `${apiUrl}/api/auth/logIn`,
            method: "post",
            data: data,
        });
        return response;
    },
    async createNft(data) {
        const response = await axios({
            url: `${apiUrl}/api/users/createNft`,
            method: "post",
            data: data,
            headers: {
                Authorization:
                    "Bearer " + store.state.userCredentials.token,
            },
        });
        return response
    }
    ,
    async getNetworks() {
        const response = await axios({
            url: `${apiUrl}/api/auth/getNetworks`,
            method: "get",
            headers: {
                Authorization: "Bearer " + store.state.userCredentials.token,
            },
        });
        return response;

    },
    async getSports() {
        const res = axios({
            url: `${apiUrl}/api/auth/getSports`,
            method: "get",
            headers: {
                Authorization: "Bearer " + store.state.userCredentials.token,
            },
        });
        return res;
    },
    async getTokensByNetwork(id) {
        const response = await axios({
            url: `${apiUrl}/api/auth/getTokens/${id}`,
            method: "get",
            headers: {
                Authorization:
                    "Bearer " + store.state.userCredentials.token,
            },
        });
        return response;
    },
    async getTournamentsById(id) {
        const response = await axios({
            url: `${apiUrl}/api/auth/getToursAdminV2`,
            params: {
                sportId: id,
            },
            method: "get",
            headers: {
                Authorization:
                    "Bearer " + store.state.userCredentials.token,
            },
        });
        return response;
    },
    async getTeamById(id) {
        const response = await axios({
            url: `${apiUrl}/api/auth/getTeams/${id}`,
            method: "get",
            headers: {
                Authorization:
                    "Bearer " + store.state.userCredentials.token,
            },
        });
        return response.data.data.teams
    },
    async getUsers(page, from, to, itemPerPage = 12) {
        // console.log("page, from, to", page, from, to);
        const response = await axios({
            url: `${apiUrl}/api/users/getUsers`,
            params: {
                page: page,
                fromDate: from,
                toDate: to,
                itemPerPage
            },
            method: "get",
            headers: {
                Authorization: "Bearer " + store.state.userCredentials.token,
            },
        });
        return response;
    },
    // async getUsersByDateRange(from, to) {
    //     const res = await axios({
    //         url: `${apiUrl}/api/users/getUsers`,
    //         params: {
    //             fromDate: from,
    //             toDate: to,
    //         },
    //         method: "get",
    //         headers: {
    //             Authorization: "Bearer " + store.state.userCredentials.token,
    //         },
    //     });
    //     return res
    // },
    async getTournaments(page, from, to, itemPerPage = 12) {
        // console.log("page", page, from, to);
        const response = await axios({
            url: `${apiUrl}/api/auth/getToursAdminV2`,
            method: "get",
            params: {
                page: page,
                fromDate: from,
                toDate: to,
                itemPerPage: itemPerPage,
            },
            headers: {
                Authorization: "Bearer " + store.state.userCredentials.token,
            },
        });
        return response

    },
    // async getTournamentsbyPage(page) {
    //     const response = await axios({
    //         url: `${apiUrl}/api/auth/getToursAdminV2`,
    //         method: "get",
    //         params: { page: page },
    //         headers: {
    //             Authorization: "Bearer " + store.state.userCredentials.token,
    //         },
    //     });
    //     return response

    // },

    async getNftUsage(page, nftId, matchId, itemPerPage = 12) {
        // console.log("nftId, matchId", page, nftId, matchId);
        const response = await axios({
            url: `${apiUrl}/api/users/getNftUses`,
            params: {
                page: page,
                nftId: nftId,
                matchId: matchId,
                itemPerPage
            },
            method: "get",
            headers: {
                Authorization: "Bearer " + store.state.userCredentials.token,
            },
        });
        return response
    },
    async getCreatedNfts(page, from, to, tourId, itemPerPage = 12) {
        // console.log("page", page, from, to);
        const response = await axios({
            url: `${apiUrl}/api/users/getNftByTournamentId`,
            params: {
                page: page,
                fromDate: from,
                toDate: to,
                tourId: tourId,
                itemPerPage
            },
            method: "get",
            headers: {
                Authorization: "Bearer " + store.state.userCredentials.token,
            },
        });
        return response
    },
    async getNftByTournamentId(id) {
        const response = await axios({
            url: `${apiUrl}/api/users/getNftByTournamentId`,
            params: { tourId: id },
            method: "get",
            headers: {
                Authorization: "Bearer " + store.state.userCredentials.token,
            },
        });
        return response
    },

    async getNftSales(page, from, to, nftId, tokenId) {
        // console.log("page", page, from, to, nftId, tokenId);
        return await axios({
            url: `${apiUrl}/api/users/getSaleDetails`,
            params: {
                page: page,
                fromDate: from,
                toDate: to,
                nftId: nftId,
                tokenId: tokenId,
            },
            method: "get",
            headers: {
                Authorization: "Bearer " + store.state.userCredentials.token,
            },
        });
    },
    async getCompleteMatches(page, from, to, status,
        bySport,
        byTour,) {
        // console.log("page", page, from, to, status,
        //     bySport,
        //     byTour);

        return await axios({
            url: `${apiUrl}/api/users/getMatchesAdmin`,
            params: {
                page: page,
                fromDate: from,
                toDate: to,
                status: status,
                sport: bySport,
                tourId: byTour,
            },
            //   params: { page: 1 },
            method: "get",
            headers: {
                Authorization: "Bearer " + store.state.userCredentials.token,
            },
        });
    },
    async getUpcommingMatches(page, from, to, tournament_id, sport, match_status, itemsPerPage = null) {
        console.log("page, tournament_id, sport, match_status,", page, tournament_id, sport, match_status, itemsPerPage);
        return await axios({
            url: `${apiUrl}/api/users/getMatchDetails`,
            params: {
                page,
                fromDate: from,
                toDate: to,
                tournament_id,
                sport,
                match_status,
                itemsPerPage
            },
            //   params: { page: 1 },
            method: "get",
            headers: {
                Authorization: "Bearer " + store.state.userCredentials.token,
            },
        });
    },
    async getAllNfts() {
        const response = await axios({
            url: `${apiUrl}/api/auth/getNfts`,
            method: "get",
            headers: {
                Authorization: "Bearer " + store.state.userCredentials.token,
            },
        });
        return response;

    },
    async getMatches() {
        console.log("matche enterd");
        const response = await axios({
            url: `${apiUrl}/api/users/getMatchesFilter`,
            method: "get",
            headers: {
                Authorization: "Bearer " + store.state.userCredentials.token,
            },
        });
        console.log("matches response got it");
        return response
    },
    async changeNftStatus(id, status) {
        const response = await axios({
            url: `${apiUrl}/api/users/updateNftStatus`,
            params: {
                nftId: id,
                nftStatus: status,
            },
            method: "post",
            headers: {
                Authorization: "Bearer " + store.state.userCredentials.token,
            },
        });
        return response

    }
}