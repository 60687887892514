<template>
  <div class="main">
    <div class="algncnt table-header row" v-if="!loading">
      <!-- <div class="filter-content" > -->
      <div class="col-4">
        <!-- <div class="label">
          <span>Filter by Matches :</span>
        </div> -->
        <select
          class="form-select"
          label="Default select example"
          v-model="selectedMatch"
          @change="getNftUsage()"
        >
          <option value="null" disabled>Filter by Matches</option>
          <option
            v-if="matches.length > 0"
            v-for="match in matches"
            v-bind:value="match"
            v-bind:key="match.match_name"
          >
            {{ match.match_name }}
          </option>
        </select>
      </div>
      <div class="col-4 my-2">
        <!-- <div class="label">
          <span>Filter by Nft :</span>
        </div> -->
        <select
          class="form-select"
          label="Default select example"
          v-model="selectedNft"
          @change="getNftUsage()"
        >
          <option value="null" disabled>Filter by Nft</option>
          <option
            v-for="nft in nftData"
            v-bind:value="nft"
            v-bind:key="nft.nft_name"
          >
            {{ nft.nft_name }}
          </option>
        </select>
      </div>
      <div class="col-4 my-2">
        <button
          v-if="filterdata"
          type="button"
          class="btn btn-secondary"
          @click="clearFilter()"
        >
          Clear all
        </button>
      </div>
      <div class="col-4 my-2">
        <!-- <vue-json-to-csv
          v-if="!loading"
          :json-data="nftUsage"
          :csv-title="'NFT Usage'"
        >
          <b-button> Download CSV </b-button>
        </vue-json-to-csv> -->
        <b-button @click="downloadCSV()"> Download CSV </b-button>
      </div>
      <!-- </div> -->
    </div>
    <br />
    <div class="loadoverlay" v-if="loading">
      <b-spinner></b-spinner>
    </div>
    <p v-if="!loading && isdata" class="nodata">
      No Data Available at the moment.
    </p>

    <div v-if="!loading && !isdata">
      <b-table
        hover
        :fields="fields"
        :items="nftUsage"
        :per-page="perPage"
        outlined
        no-sort-reset
      >
        <template #cell(btn)="data">
          <b-button @click="rowdata(data)" size="sm"> click </b-button>
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        @change="getNftUsage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="my-table"
        pills
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import VueJsonToCsv from "vue-json-to-csv";
import { apiService } from "@/services/apiService";
import { utills } from "@/services/utill";

export default {
  data() {
    return {
      // sendUrl: "https://prediction-api.rage.fan",
      // sendUrl: this.$store.state.apiUrl,
      sendUrl: apiService.getUrl(),
      nftUsage: [],
      perPage: 15,
      currentPage: 1,
      loading: true,
      isdata: false,
      fields: [
        {
          key: "nft_name",
          label: "Nft Name",
          color: "red",
        },
        {
          key: "nft_used_time",
          label: "nft Used time",
        },
        {
          key: "nft_used_match",
          label: "nft_used_match",
        },
        {
          key: "user_id",
          label: "user_id",
        },
        // {
        //   key: "prediction_result",
        //   label: "prediction_result",
        // },
        {
          key: "winning_amount",
          label: "Winning amount",
        },
        {
          key: "match_result",
          label: "Match Result",
        },
        // {
        //   key: "btn",
        //   label: "Button",
        // },
      ],
      matches: [],
      selectedMatch: null,
      nftData: [],
      selectedNft: null,
      totalPages: 1,
    };
  },
  components: {
    VueJsonToCsv,
  },
  computed: {
    rows() {
      return this.nftUsage.length * this.totalPages;
    },
    filterdata() {
      if (this.selectedNft === null && this.selectedMatch === null) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    async getNftUsage(e) {
      try {
        this.nftUsage = [];
        this.loading = true;
        if (e) {
          this.currentPage = e;
        }
        let nftId = null;
        let matchId = null;
        if (this.selectedNft) {
          nftId = this.selectedNft.id;
        }
        if (this.selectedMatch) {
          matchId = this.selectedMatch.id;
        }

        const response = await apiService.getNftUsage(
          this.currentPage,
          nftId,
          matchId
        );
        // this.Tournaments= response.data.data.tournaments;
        console.log("response nftUsage", response);
        response.data.data.txns.forEach((e) => {
          // console.log("result", e.match_result);
          const data = {
            nft_used_time: moment
              .utc(e.nft_used_time)
              .format("DD-MM-YYYY-h:mm-A"),
            nft_name: e.nft_name,
            nft_used_match: e.nft_used_match,
            user_id: e.user_id,
            prediction_result: e.prediction_result,
            match_result: e.match_result
              ? e.match_result
              : "Waiting For Match Result",
            winning_amount: e.winning_amount,
          };
          this.nftUsage.push(data);
          // console.log(moment(e.end_date).format("YYYY-MM-DD"));
        });
        //  console.log("resp",this.nftUsage)
        //  Check for Length for pagination
        this.totalPages = response.data.data.totalPages;
        if (this.nftUsage.length < 12) {
          this.perPage = this.nftUsage.length;
        } else {
          this.perPage = 12;
        }
        this.loading = false;
        if (this.nftUsage.length > 0) {
          this.isdata = false;
        } else {
          this.isdata = true;
        }
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.isdata = true;
      }
    },
    async getMatchFilter() {
      try {
        const response = await apiService.getMatches();
        this.matches = response.data.data.matches;
        console.log("response matches fn", response);
      } catch (error) {
        console.log(error);
      }
    },
    async getAllNfts() {
      try {
        const response = await apiService.getAllNfts();
        console.log("response nfts", response);
        this.nftData = response.data.data.nft;
      } catch (error) {
        console.log(error);
      }
    },
    async downloadCSV() {
      let nftId = null;
      let matchId = null;
      if (this.selectedNft) {
        nftId = this.selectedNft.id;
      }
      if (this.selectedMatch) {
        matchId = this.selectedMatch.id;
      }

      const items = this.nftUsage.length * this.totalPages;
      const csvdata = [];
      try {
        const response = await apiService.getNftUsage(
          this.currentPage,
          nftId,
          matchId,
          items
        );
        response.data.data.txns.forEach((e) => {
          // console.log("result", e.match_result);
          const data = {
            nft_used_time: moment
              .utc(e.nft_used_time)
              .format("DD-MM-YYYY-h:mm-A"),
            nft_name: e.nft_name,
            nft_used_match: e.nft_used_match,
            user_id: e.user_id,
            prediction_result: e.prediction_result,
            match_result: e.match_result ? e.match_result : "Pending...",
            winning_amount: e.winning_amount,
          };
          csvdata.push(data);
          // console.log(moment(e.end_date).format("YYYY-MM-DD"));
        });
        //  console.log("resp",this.nftUsage)

        // console.log("response", response);
        utills.downloadCSV("NFT Usage.csv", csvdata);
      } catch (error) {
        console.log(error);
      }
    },

    rowdata(data) {
      // console.log(data.item);
    },
    async clearFilter() {
      window.location.reload();
    },
  },
  created() {
    // this.getMatches();
    this.getAllNfts();
    this.getNftUsage();
    this.getMatchFilter();
  },
};
</script>

<style>
.nodata {
  color: red;
}
/* .loadoverlay {
  position: fixed;
  height: 80vh;
  width: 65vw;
  display: flex;
  align-items: center;
  justify-content: center;
} */
/* .table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.filter-content {
  display: flex;
  align-items: center;
} */
</style>