import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Home from '../views/Home.vue'
import Dashboard from '../views/Dashboard.vue'
import store from "../store/index"
import Tbview from "../views/Tbview.vue"
import CreatedNfts from "../views/CreatedNfts.vue"
import Wrapper from "../views/wrapper/Wrapper.vue"
import Users from "../views/Users.vue"
import NftSales from "../views/NftSales.vue"
import NftUsage from "../views/Nftusage.vue"
import Completed from "../views/Completed.vue";
import UpCommingMatches from "../views/UpCommingMatches.vue";






Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: "/",
    component: Wrapper,
    children: [
      // {
      //   path: '/about',
      //   name: 'About',
      //   // route level code-splitting
      //   // this generates a separate chunk (about.[hash].js) for this route
      //   // which is lazy-loaded when the route is visited.
      //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
      // },
      {
        path: '/dashboard',
        name: 'Create NFT',
        component: Dashboard
      },
      {
        path: '/tournaments',
        name: 'Tournaments',
        component: Tbview,
      },
      {
        path: '/users',
        name: 'Users',
        component: Users,
      },
      {
        path: '/nftSales',
        name: 'NftSales',
        component: NftSales,
      },
      {
        path: '/nftUsage',
        name: 'nftUsage',
        component: NftUsage,
      },
      {
        path: '/creatednfts',
        name: 'NFTs',
        component: CreatedNfts,
      },
      {
        path: '/completed',
        name: 'Matches',
        component: Completed,
      },
      {
        path: '/upcoming',
        name: 'UpComing',
        component: UpCommingMatches,
      },
    ]
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {

  store.dispatch('fetchAccessToken');
  // console.log(store.state.userCredentials);
  if (to.fullPath !== '/') {
    if (!store.state.userCredentials) {
      next('/');
    }
  }
  if (to.fullPath === '/') {
    if (store.state.userCredentials) {
      next('/dashboard');
    }
  }
  next();
});

export default router
