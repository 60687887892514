import { ethers } from "ethers";
import ercAbi from "../assets/abi/erc20Abi.json"
import { utills } from "./utill";

export const erc20 = {
    async approve(signer, spender, winningToken, amount = "100000000000000000000000") {
        const erc20C = new ethers.Contract(winningToken, ercAbi, signer);
        let tx = await erc20C.approve(spender, amount);
        let receipt = await tx.wait();
        return receipt;
    },
    async allowance(signer, spender, winningToken) {
        try {
            const erc20C = new ethers.Contract(winningToken, ercAbi, signer);
            let allowanceWei = await erc20C.allowance(signer.getAddress(), spender);
            let allowance = utills.format(allowanceWei, 18);
            return allowance;
        }
        catch (e) {
            console.log("Error while get allowance", e);
        }
    }
}